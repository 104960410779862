/* eslint-disable */
import config from '../utils/config';

import { getB2BPage } from './services/parentFunctions';

import uuid from 'react-uuid';

class ChatConnection {
  static instance = null;

  constructor() {
    const urlWidth = config.siteAcer === 'https://br-store.acer.com/' ? 14 : 13;
    let timestamp = new Date().getTime() + uuid();
    let attendant = sessionStorage.getItem('attendant_to');
    let login_to = sessionStorage.getItem('attendant_login');

    if (!sessionStorage.getItem('hash_connection_chat')) {
      sessionStorage.setItem('hash_connection_chat', timestamp);
    } else {
      timestamp = sessionStorage.getItem('hash_connection_chat');
    }

    this.url = `${config.backend.wss}?hash_connection_chat=${timestamp}${
      login_to ? '&responsible=' + login_to : ''
    }`;
    this.identificationMsg = {
      action: 'sendmessage',
      identifier: getB2BPage() ? 'client_b2b' : 'client',
      parentURL: window.parent.document.location.href,
      parentTitle: getB2BPage() ? 'B2B' : undefined,
      product_category:
        window.parent.document
          ?.getElementsByClassName('bread-crumb')[0]
          ?.getElementsByClassName('last')[0]
          ?.getElementsByTagName('span')[0]?.innerHTML || 'undefined',
      acer_PN:
        window.parent.document?.querySelector(
          'meta[property="product:retailer_item_id"]'
        )?.content || 'undefined',
      template_page: window.parent.document?.getElementById('product-page')
        ? 'product-page'
        : 'other-template',
      referrer: window?.siteReferrer,
      hash_connection_chat: sessionStorage.getItem('hash_connection_chat')
    };

    this.socket = new WebSocket(this.url);

    this.socket.onopen = event => {
      this.onConnectionOpen(event);
    };

    this.socket.addEventListener('close', function() {
      sessionStorage.setItem('socket', 'reload-new');
    });
  }

  static getConnection = () => {
    if (!this.instance) {
      this.instance = new ChatConnection();
      return this.instance;
    }
    return this.instance;
  };

  onConnectionOpen() {
    const callback = () => {
      this.socket.addEventListener('message', resp => {
        const data = JSON.parse(resp.data);

        if (data?.system?.trigger) {
          localStorage.setItem(
            'triggerInfo',
            JSON.stringify(data.system.trigger)
          );
        }

        if (data?.system?.ip) {
          localStorage.setItem('ip', data.system.ip);
          localStorage.setItem('blockedIP', data.system.blocked);
        }
      });
    };
    this.send(this.identificationMsg, callback);
    this.heartBeat();
  }

  send(message, callback) {
    this.waitForConnection(() => {
      this.socket.send(JSON.stringify(message));
      if (typeof callback !== 'undefined') {
        callback();
      }
    }, 1000);
  }

  async heartBeat() {
    while (true) {
      const wait = seconds =>
        new Promise(resolve => {
          setTimeout(resolve, seconds);
        });
      await wait(60000).then(() => {
        this.send({ action: 'heartbeat' });
      });
    }
  }

  sendDisconnect() {
    this.send({
      action: 'sendmessage',
      disconnect: 1,
      login_to: sessionStorage.getItem('attendant_login')
    });
  }

  sendInput({ message }) {
    const to = sessionStorage.getItem('context_to') || null;

    this.send({
      action: 'sendcontrol',
      typing: message,
      hash_connection_chat: sessionStorage.getItem('hash_connection_chat'),
      to
    });
  }

  sendMessage(
    text,
    filename,
    sharedName,
    sharedEmail,
    sharedLastResponsible,
    chatOptionsHistory,
    urlAnexoRaw
  ) {
    const to = sessionStorage.getItem('attendant_to')
      ? sessionStorage.getItem('attendant_to')
      : '';
    const urlWidth = config.siteAcer === 'https://br-store.acer.com/' ? 14 : 13;

    this.send({
      action: 'sendmessage',
      isAttendant: false,
      to,
      name_attachment: filename,
      data: text,
      name: sharedName,
      email: sharedEmail,
      lastConnectionIdResponsible: sharedLastResponsible,
      history: chatOptionsHistory,
      hash_connection_chat: sessionStorage.getItem('hash_connection_chat'),
      login_to: sessionStorage.getItem('attendant_login'),
      parentURL: window.parent.document.location.href,
      parentTitle: getB2BPage() ? 'B2B' : undefined,
      product_category:
        window.parent.document
          ?.getElementsByClassName('bread-crumb')[0]
          ?.getElementsByClassName('last')[0]
          ?.getElementsByTagName('span')[0]?.innerHTML || 'undefined',
      acer_PN:
        window.parent.document?.querySelector(
          'meta[property="product:retailer_item_id"]'
        )?.content || 'undefined',
      template_page: window.parent.document?.getElementById('product-page')
        ? 'product-page'
        : 'other-template',
      url_anexo_raw: urlAnexoRaw
    });
  }

  sendMessageTo(
    text,
    filename,
    to,
    sharedName,
    sharedEmail,
    sharedLastResponsible,
    chatOptionsHistory,
    urlAnexoRaw
  ) {
    const urlWidth = config.siteAcer === 'https://br-store.acer.com/' ? 14 : 13;
    sessionStorage.setItem('attendant_to', to);
    this.send({
      action: 'sendmessage',
      isAttendant: false,
      data: text,
      name_attachment: filename,
      to,
      name: sharedName,
      email: sharedEmail,
      lastConnectionIdResponsible: sharedLastResponsible,
      history: chatOptionsHistory,
      hash_connection_chat: sessionStorage.getItem('hash_connection_chat'),
      login_to: sessionStorage.getItem('attendant_login'),
      parentURL: window.parent.document.location.href,
      parentTitle: getB2BPage() ? 'B2B' : undefined,
      product_category:
        window.parent.document
          ?.getElementsByClassName('bread-crumb')[0]
          ?.getElementsByClassName('last')[0]
          ?.getElementsByTagName('span')[0]?.innerHTML || 'undefined',
      acer_PN:
        window.parent.document?.querySelector(
          'meta[property="product:retailer_item_id"]'
        )?.content || 'undefined',
      template_page: window.parent.document?.getElementById('product-page')
        ? 'product-page'
        : 'other-template',
      url_anexo_raw: urlAnexoRaw
    });
  }

  waitForConnection(callback, interval) {
    if (this.socket.readyState === 1) {
      callback();
    } else {
      setTimeout(() => {
        this.waitForConnection(callback, interval);
      }, interval);
    }
  }

  close() {
    this.socket.close();
  }

  setOnMessageCallback(callback) {
    this.socket.onmessage = event => {
      if (callback && typeof callback === 'function') callback(event);
    };
  }

  setOnCloseCallback(callback) {
    this.socket.onclose = event => {
      if (callback && typeof callback === 'function') callback(event);
    };
  }

  setOnErrorCallback(callback) {
    this.socket.onerror = event => {
      if (callback && typeof callback === 'function') callback(event);
    };
  }
}

export default ChatConnection;
