/**
 * send the satisfaction survey to Acer system, futherly this info will be used in Acer's dashboards.
 * @param {Websocket} instance - Parameters of sendMessage function.
 * @param {Object} payload - The websocket instance.
 * @param {boolean} payload.satisfied - User message to attendant.
 * @param {string} payload.message - User message to attendant.
 * @param {string} payload.hashconnection - User message to attendant.
 */
export const sendSatisfactionSurvey = (instance, data) => {
  instance.send(
    JSON.stringify({
      type: 'survey_message',
      data
    })
  );
};
