export default {
  recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  siteAcer: process.env.REACT_APP_LOJA_URL,
  cognito: {
    noAuthUrl: process.env.REACT_APP_JAVA_AUTH_URL
  },
  backend: {
    url: process.env.REACT_APP_BACKEND_URI,
    wss: process.env.REACT_APP_BACKEND_WSS,
    whatsappWss: process.env.REACT_APP_BACKEND_WSS_WHATSAPP,
    whatsappApi: process.env.REACT_APP_BACKEND_API_WHATSAPP,
    ia_no_auth: process.env.REACT_APP_IA_URL_NO_AUTH
  }
};
