/* eslint-disable */

import React, { useState } from 'react';
import Axios from 'axios';
import { Box, Button, Container, Icon, TextField } from '@material-ui/core';
import { AccessTime, TagFaces } from '@material-ui/icons';
import moment from 'moment';
import InputMask from 'react-input-mask';
import config from '../../../utils/config';

// import {
//   generatePayload,
//   generateRecaptchaToken
// } from '../../services/chatHubFunctions';

import { FormOutSideComponent } from './FormOutSide.component';

const periodBlackFriday = () => {
  const now = new Date();
  if (
    moment(now).format('YYYY-MM-DD') >=
      moment('2021-11-22').format('YYYY-MM-DD') &&
    moment(now).format('YYYY-MM-DD') <=
      moment('2021-11-28').format('YYYY-MM-DD')
  ) {
    return true;
  }
  return false;
};

// const FormOutSideComponent = ({
//   nome,
//   email,
//   setShowThanksMessage,
//   outSideHourResponse
// }) => {
//   const [name, setName] = useState(nome);
//   const [newemail, setNewEmail] = useState(email);
//   const [phone, setPhone] = useState('');
//   const [mask, setMask] = useState('(99)9999-9999');

//   const handleSubmit = async e => {
//     e.preventDefault();

//     const data = generatePayload({ name, email: newemail, phone_number: phone, step: -4, buttons: ['Não'] })

//     try {
//       await Axios.post(`${config.backend.ia_no_auth}api/v1/chathub`, {
//         ...outSideHourResponse,
//         ...data
//       }).then(response => {
//         const { data } = response;
//         if (data.tag === 'sucesso') {
//           setShowThanksMessage(true);
//         }
//       });
//     } catch (e) {
//       console.log(e);
//     }

//   };

//   const handlePhoneMask = value => {
//     value = value.replace(/\D/g, '');
//     if (value.length > 10) {
//       setMask('(99)99999-9999');
//     } else if (value.length <= 10) {
//       setMask('(99)9999-9999');
//     }
//   };

//   return (
//     <form onSubmit={e => handleSubmit(e)}>
//       <Box pt={3}>
//         <TextField
//           id="outlined-multiline-static"
//           type="text"
//           // fullWidth
//           style={{ width: '250px' }}
//           variant="outlined"
//           label="Qual é o seu nome?"
//           value={name}
//           onChange={e => setName(e.target.value)}
//           required
//         />
//       </Box>
//       <Box pt={2}>
//         <TextField
//           id="outlined-multiline-static"
//           type="text"
//           // fullWidth
//           style={{ width: '250px' }}
//           variant="outlined"
//           label="Qual é o seu email?"
//           value={newemail}
//           onChange={e => setNewEmail(e.target.value)}
//           required
//         />
//       </Box>
//       <Box pt={2}>
//         <InputMask
//           mask={mask}
//           maskChar=""
//           value={phone}

//           onPaste={e => handlePhoneMask(e.clipboardData.getData('Text'))}
//           required
//           onChange={htmlElemtEvent => {
//             setPhone(htmlElemtEvent.target.value);
//             if (htmlElemtEvent.target.value.length >= 13) {
//               setMask('(99)99999-9999');
//             } else {
//               setMask('(99)9999-99999');
//             }
//             if (htmlElemtEvent.target.value.length < 1) {
//               setMask('99999999999');
//             }
//           }}
//         >
//           {() => (
//             <TextField
//               style={{ width: '250px' }}
//               label="Qual é o seu telefone?"
//               variant="outlined"
//               required
//             />
//           )}
//         </InputMask>
//       </Box>
//       <Box pt={2} display="flex" justifyContent="end">
//         <Button type="submit" color="primary">
//           Enviar contato
//         </Button>
//       </Box>
//     </form>
//   );
// };

const OutSideComponent = ({ nome, email, outSideHourResponse }) => {
  const [showThanksMessage, setShowThanksMessage] = useState(false);

  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          <Icon>
            {!showThanksMessage ? (
              <AccessTime
                style={{ width: '100px', height: '100px', color: '#999999' }}
              />
            ) : (
              <TagFaces
                style={{ width: '100px', height: '100px', color: '#999999' }}
              />
            )}
          </Icon>
        </Box>
        {periodBlackFriday() === true ? (
          <Box>
            O atendimento deste chat retornará às 8h00. Enquanto isso, por
            favor, abra um chamado através do botão abaixo:
          </Box>
        ) : (
          <>
            {!showThanksMessage ? (
              <FormOutSideComponent
                nome={nome}
                email={email}
                setShowThanksMessage={setShowThanksMessage}
                outSideHourResponse={outSideHourResponse}
              />
            ) : (
              <Box>Obrigado. Agora você pode fechar esta janela.</Box>
            )}
          </>
        )}
        {/* <br />
        <Link
          component={Button}
          // href="https://acer-conecta.com/nova-solicitacao"
          href={process.env.REACT_APP_ACER_CONNECTA}
          target="_blank"
          rel="noreferrer"
          id="link_to_acer_conecta"
        >
          ABERTURA DE CHAMADO
        </Link> */}
      </Box>
    </Container>
  );
};

export default OutSideComponent;
