/**
 * Botao de finalização do chat e reinício
 */
import React from 'react';
import { Button } from '@material-ui/core';
import { reloadIframe } from '../../services/parentFunctions';

const EndChatSessionComponent = () => {
  return (
    <>
      Atendimento finalizado
      <br />
      <br />
      <Button
        id="button-newchat"
        onClick={() => reloadIframe()}
        variant="contained"
        fullWidth
      >
        Novo Atendimento
      </Button>
    </>
  );
};

export default EndChatSessionComponent;
