import { playIframeAudio } from './parentFunctions';
import { cleanSessionStorage } from './chatFunctions';
import { setMessageViewed } from './sendMessageFunctions';

import AttendantBubble from '../components/bubbles/AttedantBubble.component';

export function recieveMessage({
  socket,
  event,
  renderCustomComponent,
  renderEndChatSession,
  setClientInteractedWithAttendant,
  setOutOfHours,
  setOutOfHoursMessage,
  setHashConnectionChatForEvaluation,
  setSharedName,
  setSharedEmail,
  setFrom,
  setTo
}) {
  const fisrtMessage = {};

  const message = JSON.parse(event.data);

  if (event.data.message) {
    const { stringMessage } = JSON.parse(event.data);
    fisrtMessage.message = stringMessage;
  }

  if (message.from) {
    sessionStorage.setItem('attendant_to', message.from);
    // chatRingtoneMessage.play();
    playIframeAudio();
  }

  function getPosition(text) {
    const pos = String(text).split(' ')[3];
    return pos;
  }

  if (message.login) {
    sessionStorage.setItem('attendant_login', message.login);
  }

  if (message.position) {
    getPosition(event.data);
    renderCustomComponent(AttendantBubble, {
      message: `Sua ${message.position.toLowerCase()}º. Por favor, aguarde para ser atendido.`
    });
    // playIframeAudio();
    // chatRingtoneMessage.play();
  } else {
    const response = JSON.parse(event.data);

    if (response?.system?.isNotWithinAttendanceHours) {
      setClientInteractedWithAttendant(false);
      setOutOfHoursMessage(response?.system?.message || 'Fora do Horário');
      setOutOfHours(true);
    }

    if (response?.system?.action === 'Change_Clerk') {
      sessionStorage.setItem('attendant_login', response?.system?.login);
    }

    if (response?.system?.action === 'KICK_OUT') {
      setHashConnectionChatForEvaluation(
        sessionStorage.getItem('hash_connection_chat')
      );
      socket.sendDisconnect();
      cleanSessionStorage();
      return renderEndChatSession('', true);
    }

    if (response?.system?.action === 'IDENTIFY') {
      setSharedName(response?.system?.name);
      setSharedEmail(response?.system?.email);
    }

    if (response.to === response.from) {
      setFrom(response.from);
      sessionStorage.setItem('context_from', response.from);
      return;
    }

    if (response.to !== response.from) {
      // quando houver mensagem do atendente
      // chatRingtoneMessage.play();
      playIframeAudio();
      setTo(response.from);
      sessionStorage.setItem('context_to', response.from);
      renderCustomComponent(AttendantBubble, {
        message: response.message,
        url: response.message,
        filename: response.name_attachment,
        timestamp: response.date,
        sender: response.login
      });

      setMessageViewed();
    }

    if (fisrtMessage?.message?.search('http') >= 0) {
      const listLinks = document.getElementsByTagName('a');
      const links = Array.from(listLinks);
      links.forEach(item => {
        item.setAttribute('target', '_blank');
      });
    }
  }
}
