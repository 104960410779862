import AttendantBubble from '../../../components/bubbles/AttedantBubble.component';
import { playIframeAudio } from '../../parentFunctions';

export const onReceiveAttendantMessage = (
  messageFromServer,
  renderCustomComponent
) => {
  renderCustomComponent(AttendantBubble, {
    message: messageFromServer.data.message,
    sender: messageFromServer.data.sender_name
  });
  playIframeAudio();
};
