import Axios from 'axios';
import config from '../../utils/config';
import getQueryParams from '../../utils/getQueryParams';

export async function chatHubSubmit(flowTypeSubmit, nome, email, mensagem) {
  const b2bValue = getQueryParams('b2b');
  const recaptchToken =
    process.env.REACT_APP_ACTIVE_RECAPTCHA === 'true' &&
    (await window?.recaptchaExecute(window?.recaptchaSiteKey));
  const postPayloadOfFirstRequest = {
    name: nome,
    email,
    message: mensagem,
    tag: '',
    accuracy: 1,
    business_type: b2bValue ? 'B2B' : 'B2C',
    step: -3,
    feedback: false,
    attendance: true,
    flow_type: flowTypeSubmit || '',
    recaptchToken,
    hash_connection_chat: sessionStorage.getItem('hash_connection_chat'),
    buttons: ['Sim']
  };

  await Axios.post(
    `${config.backend.ia_no_auth}lambda/chathub`,
    postPayloadOfFirstRequest
  );
}

export async function generateRecaptchaToken() {
  const recaptchToken =
    process.env.REACT_APP_ACTIVE_RECAPTCHA === 'true' &&
    (await window?.recaptchaExecute(window?.recaptchaSiteKey));

  return recaptchToken;
}

export function openTicketOnNewWindow(botResponse, option) {
  /**
   * Caso o bot não consiga ajudar e o cliente queira abrir um chamado. Abrir nova janela com o formulário de abertura de chamado
   */
  if (
    botResponse.flow_type === 'posvenda' &&
    botResponse.step === -4 &&
    botResponse.buttons[0] === 'Sim' &&
    botResponse.buttons[1] === 'Não' &&
    option === 'Sim'
  ) {
    window.open(
      `${process.env.REACT_APP_ACER_CONNECTA}${
        botResponse.business_type === 'B2B' ? '/?b2b=true' : ''
      }`,
      '_blank'
    );
  }
}

export async function generatePayload({
  nome,
  email,
  mensagem,
  tag,
  accuracy,
  step,
  feedback,
  attendance,
  flow_type,
  business_type,
  buttons,
  botResponse,
  userResponse,
  overrideStepToMinusOne,
  overrideStepToOne,
  option,
  phone_number,
  outSideFormResponse,
  isB2B
}) {
  const recaptchToken = await generateRecaptchaToken();
  const b2bValue = getQueryParams('b2b');

  if (!botResponse) {
    return {
      name: nome,
      email,
      message: mensagem,
      tag: tag || '',
      accuracy: accuracy || 1,
      step: step || 0,
      business_type: business_type || b2bValue ? 'B2B' : 'B2C',
      feedback: feedback || false,
      attendance: attendance || false,
      flow_type: flow_type || '',
      recaptchToken,
      hash_connection_chat: sessionStorage.getItem('hash_connection_chat'),
      buttons: buttons || [],
      phone_number,
      isB2B
    };
  }

  const getStep = () => {
    if (overrideStepToOne) return 1;
    if (overrideStepToMinusOne) return -1;
    return Number(botResponse.step);
  };

  if (botResponse) {
    return {
      ...botResponse,
      message: userResponse || botResponse?.message,
      business_type: b2bValue ? 'B2B' : 'B2C',
      flow_type: overrideStepToMinusOne ? 'prevenda' : botResponse.flow_type,
      step: getStep(),
      buttons: option ? [option] : [],
      hash_connection_chat: sessionStorage.getItem('hash_connection_chat')
    };
  }

  if (outSideFormResponse) {
    return {
      name: nome,
      email,
      phone_number,
      step: step || 0,
      recaptchToken,
      hash_connection_chat: sessionStorage.getItem('hash_connection_chat'),
      buttons: buttons || []
    };
  }
}

export async function submitOnHintScreen(
  nome,
  email,
  mensagem,
  setHintScreenOpenState,
  setOutSideHour,
  setOutSideHourResponse
) {
  // const recaptchToken = await generateRecaptchaToken();
  /**
   * Quando o usuário optar por Não, na tela inicial do chat, renderiza a HintScreen,
   * e é enviado o payload abaixo para o chathub
   */

  const b2bValue = getQueryParams('b2b');

  const postPayloadOfFirstRequest = await generatePayload({
    nome,
    email,
    message: mensagem,
    tag: '',
    accuracy: 1,
    step: -3,
    business_type: b2bValue ? 'B2B' : '',
    feedback: false,
    attendance: true,
    flow_type: 'prevenda',
    // recaptchToken,
    // hash_connection_chat: sessionStorage.getItem('hash_connection_chat'),
    buttons: ['Não']
  });

  // const postPayloadOfFirstRequest = {
  //   name: nome,
  //   email,
  //   message: mensagem,
  //   tag: '',
  //   accuracy: 1,
  //   step: -3,
  //   feedback: false,
  //   attendance: true,
  //   flow_type: 'prevenda',
  //   recaptchToken,
  //   hash_connection_chat: sessionStorage.getItem('hash_connection_chat'),
  //   buttons: ['Não']
  // };

  const { data } = await Axios.post(
    `${config.backend.ia_no_auth}lambda/chathub`,
    postPayloadOfFirstRequest
  );

  setHintScreenOpenState(true);

  if (!data.is_in_attendance_time) {
    setOutSideHour(true);
    setOutSideHourResponse(data);
  }
}
