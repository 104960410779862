import React from 'react';

const Load = () => {
  return (
    <div className="exercise-dialogue-text">
      <div className="stage">
        <div className="dot-elastic" />
      </div>
    </div>
  );
};

export default Load;
