import { getHashConnection } from '../helpers/getHashConnection';

/**
 * close the chat
 * @param {WebSocket} instance - The websocket instance
 */
export const closeService = instance => {
  instance.send(
    JSON.stringify({
      type: 'closing_message',
      data: {
        publisher_hashconnection: getHashConnection()
      }
    })
  );
};
