/**
 * Componente para anexar arquivos ao chat.
 */
import React from 'react';

import { Button, Box, Typography } from '@material-ui/core';
import { AttachFile, Delete } from '@material-ui/icons';

const FileNameSection = ({ classes, submitAttachmentFile }) => (
  <span className={classes.fileNameSpan}>{submitAttachmentFile.name}</span>
);

const UploadFileSection = ({
  socket,
  // messageViewed,
  classes,
  // setClientFile,
  submitAttachment,
  submitAttachmentFile,
  sendViewedText
}) => {
  return (
    <>
      <label
        htmlFor="upload-file"
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <input
          style={{ display: 'none' }}
          id="upload-file"
          name="upload-file"
          type="file"
          onChange={e => {
            // setClientFile(e.target.value);
            submitAttachment(e.target.files[0]);
          }}
        />
        {!submitAttachmentFile && (
          <Button
            variant="contained"
            color="primary"
            component="span"
            onClick={() => sendViewedText(socket)}
          >
            <Box display="flex" alignItems="center" style={{ gap: 5 }}>
              <AttachFile style={{ fontSize: '12px' }} />
              <Typography style={{ fontSize: '12px', fontWeight: 'bold' }}>
                Inserir arquivo
              </Typography>
            </Box>
          </Button>
        )}
      </label>
      {submitAttachmentFile && (
        <Box
          display="flex"
          style={{ gap: 3 }}
          alignItems="center"
          justifyContent="space-between"
        >
          <FileNameSection {...{ classes, submitAttachmentFile }} />
          <Button
            variant="contained"
            className={classes.redButton}
            size="small"
            component="span"
            onClick={() => {
              sendViewedText(socket);
              // setClientFile('');
              submitAttachment('');
            }}
          >
            <Delete />
          </Button>
        </Box>
      )}
    </>
  );
};

export default UploadFileSection;
