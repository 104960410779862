import { createTheme, MuiThemeProvider } from '@material-ui/core';
import React from 'react';
import { Provider } from 'react-redux';
import ChatBox from './ChatBox/ChatBox';
import GlobalStyle from './global';
import { store } from './store/store';

const theme = createTheme({
  palette: {
    primary: {
      main: '#80c342'
    }
  }
});

function App() {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <GlobalStyle />
        <ChatBox />
      </MuiThemeProvider>
    </Provider>
  );
}

export default App;
