import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
// import GetAppIcon from '@material-ui/icons/GetApp';
// import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import profileImage from '../../../assets/images/acer_ico.png';
import { hasNewChatFeature } from '../../services/hasNewChatFeature';

const Container = styled.div`
  display: flex;
`;

const Logo = styled.div`
  img {
    max-width: 25px;
  }
  display: flex;
  flex-direction: column;
  font-size: 10px;
  align-items: center;
`;

const Bubble = styled.div`
  border: 1px solid #e4e4e4;
  padding: 15px 25px;
  border-radius: 10px;
  margin-left: 10px;
  white-space: pre-line;
  max-width: 74%;
  // word-break: break-all;
`;

const Timestamp = styled.div`
  margin-top: 4px;
`;

const Sender = styled.div`
  color: #89898b;
  font-size: 13px;
  text-align: right;
`;

// const textLink = message => {
//   return (
//     <a target="_blank" title={message} href={message} rel="noreferrer">
//       Ver arquivo/link
//     </a>
//   );
// };

// const searchLink = message => {
//   return message?.search('http');
// };

// const searchUrlInString = message => {
//   const msg = message?.split(' ');

//   return msg?.map(
//     item =>
//       msg.length > 0 && (
//         <span key={item} style={{ marginRight: '10px', float: 'left' }}>
//           {!searchLink(item) ? textLink(item) : item}
//         </span>
//       )
//   );
// };

function urlify(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, url => {
    return `<a target="_blank" href="${url}" title="${url}" rel="noopener noreferrer" style="word-break: break-all">${url}</a>`;
  });
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
}

const AttendantBubble = ({
  component,
  message,
  url,
  filename,
  timestamp,
  sender = '',
  files = []
}) => {
  const messages = message?.split('\n');
  const hasMultipleFiles = files.length > 0;

  const { nicknames } = useSelector(state => state.nicknames);

  const findNickname = search => {
    const findedNickname = nicknames?.find(
      loadedNickname => loadedNickname.username === search
    );

    if (findedNickname?.nickname) {
      return findedNickname.nickname;
    }

    return search;
  };

  const messagesToRender = messages?.map(item => {
    let updatedItem = item;
    if (item.includes(sender)) {
      updatedItem = item.replace(sender, findNickname(sender));
    }

    return (
      <p
        key={item}
        style={{ marginTop: '0px' }}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: urlify(updatedItem)
        }}
      />
    );
  });

  return hasMultipleFiles ? (
    <Container>
      <Logo>
        <img src={profileImage} alt="attendant logo" />
        <Timestamp>{timestamp && moment(timestamp).format('HH:mm')}</Timestamp>
      </Logo>
      <Bubble>
        {Array.isArray(messages) && messages.length > 0
          ? messagesToRender
          : null}
        {files.map(file => (
          <p>
            <a
              target="_blank"
              href={file?.url}
              title={file?.url}
              rel="noopener noreferrer"
              style={{ wordBreak: 'break-all' }}
            >
              {file?.filename}
            </a>
          </p>
        ))}
        <Sender>{findNickname(sender)}</Sender>
      </Bubble>
    </Container>
  ) : (
    <Container>
      <Logo>
        <img src={profileImage} alt="attendant logo" />
        <Timestamp>{timestamp && moment(timestamp).format('HH:mm')}</Timestamp>
      </Logo>
      {component ? (
        <Bubble>{component}</Bubble>
      ) : (
        <Bubble>
          {filename && filename !== 'false' ? (
            <>
              {hasNewChatFeature() &&
              Array.isArray(messages) &&
              messages.length > 0
                ? messagesToRender
                : null}
              <p>
                <a
                  target="_blank"
                  href={url}
                  title={url}
                  rel="noopener noreferrer"
                  style={{ wordBreak: 'break-all' }}
                >
                  {filename}
                </a>
              </p>
            </>
          ) : (
            messagesToRender
          )}
          <Sender>{findNickname(sender)}</Sender>
        </Bubble>
      )}
    </Container>
  );
};

export default AttendantBubble;
