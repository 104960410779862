import { isMobile } from 'react-device-detect';
import workTime from '../../utils/workTime';

let idTriggerChatSession = 0;
let timerTrigger = 0;
const firstTimer = 60000; // 60 segundos (1min)
const secondTimer = 600000; // 600 segundos (10min)
let triggerInterval;

export function activateTrigger(
  newTimer,
  renderTriggerChatSession,
  toggleChatWindow
) {
  if (sessionStorage.getItem('deactivateTrigger') !== '1' && workTime()) {
    idTriggerChatSession = setInterval(() => {
      if (
        !window?.chatActivate &&
        sessionStorage.getItem('deactivateTrigger') !== '1'
      ) {
        renderTriggerChatSession(
          'Olá tudo bem?</br></br>Seja bem vindo a Loja oficial da Acer!</br></br>Posso ajudá-lo na escolha do produto ideal às suas necessidades. Qual seria o uso do equipamento?'
        );
        toggleChatWindow();
      }
    }, newTimer);
  }
}

export function setTriggerInStorage() {
  if (sessionStorage.getItem('timerTrigger') === 'secondTimer') {
    timerTrigger = secondTimer;
  } else {
    sessionStorage.setItem('timerTrigger', 'firstTimer');
    timerTrigger = firstTimer;
  }
}

export function triggerChatSession(renderTriggerChatSession, toggleChatWindow) {
  /**
   * Ao receber alguma mensagem, é adicionado o trigger secundário na sessionStorage.
   */

  if (sessionStorage.getItem('deactivateTrigger') === '1') {
    return;
  }
  sessionStorage.setItem('timerTrigger', 'secondTimer');
  clearInterval(idTriggerChatSession);
  activateTrigger(
    secondTimer,
    renderTriggerChatSession,
    toggleChatWindow,
    idTriggerChatSession
  );
}

export function deactivateTrigger() {
  /**
   * Ao receber ou enviar uma mensagem, o trigger vai ser desativado.
   */
  sessionStorage.setItem('deactivateTrigger', 1);
  clearInterval(idTriggerChatSession);
}

export function activateTriggerOnEnterChat(
  renderTriggerChatSession,
  toggleChatWindow
) {
  activateTrigger(
    timerTrigger,
    renderTriggerChatSession,
    toggleChatWindow,
    idTriggerChatSession
  );
}

export function stopTrigger(stop) {
  /**
   * Para o trigger. Caso o "stop" venha "true", o trigger é parado e não mais reiniciado.
   */
  if (triggerInterval) {
    clearInterval(triggerInterval);
  }
  if (stop) {
    sessionStorage.setItem('trigger', 'stop');
  }
}

export function startTrigger(interval, stop, triggerChat, message) {
  /**
   * Início do intervalo de tempo do trigger. Inicia o intervalo de acordo com o parâmetro e atualiza a sessionStorage
   * com o trigger disparado, para ser usado posteriormente na reinicialização do chat.
   */
  triggerInterval = setInterval(() => {
    const name = `Visitante #${String(new Date().getTime()).slice(-3)}`;
    triggerChat(message, name);
    sessionStorage.setItem('trigger', interval);
    if (stop) {
      stopTrigger();
    }
  }, interval);
}

export function trigger(
  triggerChat,
  isActive,
  timeFirstTrigger,
  timeSecondTrigger,
  message
) {
  /**
   * Início do trigger. Verifica qual trigger vai ser disparado de acordo com o tempo da máquina e com o
   * último trigger disparado.
   */

  if (isActive && !isMobile) {
    const triggerOnSessionStorage = sessionStorage.getItem('trigger');
    if (triggerOnSessionStorage === null) {
      startTrigger(timeFirstTrigger * 60000, true, triggerChat, message);
    }
    if (triggerOnSessionStorage && triggerOnSessionStorage !== 'stop') {
      startTrigger(timeSecondTrigger * 60000, true, triggerChat, message);
    }
  } else {
    stopTrigger();
  }
}
