import { Box, Icon } from '@material-ui/core';
import React from 'react';
import { AccessTime } from '@material-ui/icons';

export default function BlockedBox() {
  // return (
  //   <Box mt={2} mb={3}>
  //     No momento, o chat está indisponível. Por favor, retorne mais tarde.
  //     Obrigado.
  //     <br />
  //     Seu IP: {ip}
  //   </Box>
  // );

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <Box>
        <Icon>
          <AccessTime
            style={{ width: '100px', height: '100px', color: '#999999' }}
          />
        </Icon>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        px={4}
      >
        <Box>
          No momento, o chat está indisponível. Por favor, retorne mais tarde.
          Obrigado.
          <br />
          <br />
          {/* Seu IP: <b>{ip}</b> */}
        </Box>
      </Box>
    </Box>
  );
}
