import { getB2BPage } from '../../parentFunctions';
import { getHashConnection } from '../helpers/getHashConnection';

/**
 * Check if user has an blocked IP.
 * @param {WebSocket} instance - The websocket instance
 */
export const checkBlockedIp = instance => {
  instance.send(
    JSON.stringify({
      type: 'ip_blocked',
      data: {
        hashconnection: getHashConnection(),
        connection_channel: 'chat',
        business_type: getB2BPage() ? 'B2B' : 'B2C'
      }
    })
  );
};
