import axios from 'axios';
import config from '../../../utils/config';

/**
  @param {Object} payload - send-client-identification payload
  @param {string} payload.hashconnection - chat hashconnection
  @param {string} payload.name - client name
  @param {string} payload.email - client email

*/
export const updateClientInformation = async payload => {
  const response = await axios.post(
    `${config.backend.whatsappApi}/chat/send-client-identification`,
    payload
  );

  return response.data;
};
