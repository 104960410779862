import React, { useState } from 'react';
import Axios from 'axios';
import { Box, Button, TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';
import config from '../../../utils/config';

import { getB2BPage } from '../../services/parentFunctions';

import { generatePayload } from '../../services/chatHubFunctions';

const FormOutSideComponent = ({
  nome,
  email,
  setShowThanksMessage,
  outSideHourResponse
}) => {
  const [name, setName] = useState(nome);
  const [newemail, setNewEmail] = useState(email);
  const [phone, setPhone] = useState('');
  const [mask, setMask] = useState('(99)9999-9999');

  const handleSubmit = async e => {
    e.preventDefault();

    const payload = await generatePayload({
      nome: name,
      email: newemail,
      phone_number: phone,
      flow_type: 'prevenda',
      step: -4,
      buttons: ['Não'],
      isB2B: !!getB2BPage()
    });

    try {
      await Axios.post(`${config.backend.ia_no_auth}lambda/chathub`, {
        ...outSideHourResponse,
        ...payload
      }).then(response => {
        const { data } = response;
        if (data.tag === 'sucesso') {
          setShowThanksMessage(true);
        }
      });
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  };

  const handlePhoneMask = value => {
    value = value.replace(/\D/g, '');
    if (value.length > 10) {
      setMask('(99)99999-9999');
    } else if (value.length <= 10) {
      setMask('(99)9999-9999');
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <Box>{outSideHourResponse?.message}</Box>
      <form onSubmit={e => handleSubmit(e)}>
        <Box pt={3}>
          <TextField
            id="outlined-multiline-static"
            type="text"
            // fullWidth
            style={{ width: '250px' }}
            variant="outlined"
            label="Qual é o seu nome?"
            value={name}
            onChange={e => setName(e.target.value)}
            required
          />
        </Box>
        <Box pt={2}>
          <TextField
            id="outlined-multiline-static"
            type="text"
            // fullWidth
            style={{ width: '250px' }}
            variant="outlined"
            label="Qual é o seu email?"
            value={newemail}
            onChange={e => setNewEmail(e.target.value)}
            required
          />
        </Box>
        <Box pt={2}>
          <InputMask
            mask={mask}
            maskChar=""
            value={phone}
            onPaste={e => handlePhoneMask(e.clipboardData.getData('Text'))}
            required
            onChange={htmlElemtEvent => {
              setPhone(htmlElemtEvent.target.value);
              if (htmlElemtEvent.target.value.length >= 13) {
                setMask('(99)99999-9999');
              } else {
                setMask('(99)9999-99999');
              }
              if (htmlElemtEvent.target.value.length < 1) {
                setMask('99999999999');
              }
            }}
          >
            {() => (
              <TextField
                style={{ width: '250px' }}
                label="Qual é o seu telefone?"
                variant="outlined"
                required
              />
            )}
          </InputMask>
        </Box>
        <Box pt={2} display="flex" justifyContent="end">
          <Button type="submit" color="primary">
            Enviar contato
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export { FormOutSideComponent };
