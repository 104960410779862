/**
 * Componente do título do chat
 */

import React from 'react';

import { Tooltip } from '@material-ui/core';
import MinimizeIcon from '@material-ui/icons/Minimize';
import CloseIcon from '@material-ui/icons/Close';

import { cleanSessionStorage } from '../../services/chatFunctions';

const Title = ({
  classes,
  toggleChatWindow,
  setHashConnectionChatForEvaluation,
  clientInteractedWithAttendant,
  renderEndChatSession
}) => {
  return (
    <div className={classes.chatTitleContainer}>
      <Tooltip title="Minimizar" placement="bottom">
        <MinimizeIcon
          id="icon-minimize"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            toggleChatWindow();
          }}
        />
      </Tooltip>

      <div className={classes.chatTitle}>
        Chat de <strong>Atendimento</strong> da ACER
      </div>

      <Tooltip title="Encerrar conversa" placement="bottom">
        <CloseIcon
          id="icon-close"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setHashConnectionChatForEvaluation(
              sessionStorage.getItem('hash_connection_chat')
            );
            if (!clientInteractedWithAttendant) {
              // socket.sendDisconnect();
              cleanSessionStorage();
            }
            renderEndChatSession();
          }}
        />
      </Tooltip>
    </div>
  );
};

export default Title;
