/**
 * Componente de limpeza de cache. É exibido quando o usuário tenta enviar uma mensagem sem o hash_connection
 * salvo na sessionStorage.
 */
import React from 'react';
import { Button } from '@material-ui/core';
import { cleanSessionStorage } from '../../services/chatFunctions';
import { reloadIframe } from '../../services/parentFunctions';

const ConfirmReload = ({
  clearMessages,
  socket,
  setClientInteractedWithAttendant
}) => {
  return (
    <>
      Realizada limpeza do Cache e por isso este Chat foi descontinuado. Caso
      ainda necessite de ajuda, por favor abra novo Chat.
      <br />
      <br />
      <Button
        id="button-close"
        onClick={() => {
          clearMessages();
          socket.sendDisconnect();
          cleanSessionStorage();
          setClientInteractedWithAttendant(false);
          reloadIframe();
        }}
        variant="contained"
      >
        Entendido
      </Button>
    </>
  );
};

export default ConfirmReload;
