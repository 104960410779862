/* eslint-disable no-unused-vars */
/**
 * Funções executadas ao enviar ou interagir com o chat.
 */
// eslint-disable-next-line prefer-const

import moment from 'moment';
import ClientBubble from '../components/bubbles/ClientBubble.component';
import { stopTrigger } from './triggerFunctions';
// import { deactivateTrigger } from './triggerFunctions';

import { loadLinks } from './chatFunctions';
import { sendMessage } from './websocket/messages/sendMessage';

let messageViewed = false;
let sendTyping = false;

export function sendViewedText(socket) {
  /**
   * Envia o aviso de mensagem visualizada pelo cliente.
   */
  if (!socket) return;

  if (!messageViewed && !(socket instanceof WebSocket)) {
    socket.sendInput({ message: 'Viewed' });
    messageViewed = true;
  }
}

export function sendInputTextNow(socket) {
  /**
   * Envia o aviso de usuário digitando quando se digita no input
   */

  if (!socket) return;
  if (!sendTyping && !(socket instanceof WebSocket)) {
    socket.sendInput({ message: 'Typing' });
    sendTyping = true;
    setTimeout(() => {
      socket.sendInput({ message: 'Typing off' });
      sendTyping = false;
    }, 5000);
  }
}

export function setMessageViewed() {
  messageViewed = false;
}

export function handleSendMessage({
  socket,
  renderConfirmReload,
  sharedResponseBot,
  sharedLastOption,
  sharedLastBotResponse,
  to,
  from,
  urlFile,
  humanChatUserInputValue,
  sendFile,
  sharedName,
  sharedEmail,
  sharedLastResponsible,
  urlAnexoRaw,
  renderCustomComponent,
  setHumanChatUserInputValue,
  setUrlFile,
  setClientInteractedWithAttendant
}) {
  /**
   * Função de envio de mensagem para o websocket. Ao enviar a mensagem,
   * valida o hashconnection.
   */

  sessionStorage.setItem('opened_chat', true);

  if (sessionStorage.getItem('hash_connection_chat') === null) {
    return renderConfirmReload();
  }

  if (socket instanceof WebSocket) {
    sendMessage({ instance: socket, message: humanChatUserInputValue });
  } else {
    const chatOptionsHistory = [
      ...sharedResponseBot,
      ...[
        {
          option: sharedLastOption,
          tag: sharedLastBotResponse.tag,
          nextmessage: sharedLastBotResponse.message
        }
      ]
    ];

    if (to === null && from === null) {
      socket.sendMessage(
        (!urlFile && humanChatUserInputValue) || urlFile.url_presigned,
        sendFile && urlFile.name_attachment,
        sharedName,
        sharedEmail,
        sharedLastResponsible,
        chatOptionsHistory,
        urlAnexoRaw
      );
    } else
      socket.sendMessageTo(
        (!urlFile && humanChatUserInputValue) || urlFile.url_presigned,
        sendFile && urlFile.name_attachment,
        to,
        sharedName || sessionStorage.getItem('context_name'),
        sharedEmail || sessionStorage.getItem('context_email'),
        sharedLastResponsible,
        chatOptionsHistory,
        urlAnexoRaw
      );
  }

  renderCustomComponent(ClientBubble, {
    message: humanChatUserInputValue,
    url: sendFile && urlFile.url_presigned,
    filename: sendFile && urlFile.name_attachment,
    timestamp: moment(),
    sender: sharedName
  });

  loadLinks();
  setHumanChatUserInputValue('');
  setUrlFile('');
  setClientInteractedWithAttendant(true);
  stopTrigger(true);
  // deactivateTrigger();
}
