import { Button, Typography } from '@material-ui/core';

import styled from 'styled-components';

export const inputSubmitButton = {
  marginBottom: '9px',
  height: '60px'
};
export const divider = {
  marginTop: '9px',
  marginInline: '-200%'
};

export const disabledButton = {
  background: '#ccc !important'
};

export const DisabledButton = styled(Button)`
  background: #ccc !important;
  :hover {
    background: #ccc !important;
  }
`;

export const NewButton = styled.button`
  position: absolute;
  background: none;
  right: -24px;
  border-radius: 64px;
  cursor: pointer;
  font-size: 14px;

  padding: 0;

  height: fit-content;
  width: fit-content;

  transition-duration: 500ms;
  outline: 0;
  border: 0;
  z-index: 2147483646;

  display: flex;
  align-items: center;
`;

export const CustomButton = styled(Button)`
  padding: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-width: 30px;
  height: 30px !important;
  width: 40px !important;
  max-height: 40px !important;
  max-width: 30px !important;
  background: #ccc !important;
  :hover {
    background: #ccc !important;
  }
`;

export const CustomFab = styled.div`
  position: relative;

  display: flex;
  cursor: pointer;

  width: 201px;
  height: 45px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;

  text-align: center;
  border-radius: 8px;
  background: #fff;
  font-size: 14px;
  z-index: -1;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  align-items: center;
  justify-content: space-evenly;

  margin-right: 12px;

  @media (max-width: 890px) {
    bottom: 0px;
    right: 0px;
  }

  > span {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    user-select: none;
  }
`;

export const CustomFabLabel = styled(Typography)`
  padding-right: 10px;
  font-size: 20px;
`;
