import React from 'react';
import 'react-chat-jordan/lib/styles.css';
import './ChatBox.css';
import 'styled-components';
// import workTime from '../utils/workTime';
// import OutSideComponent from './components/OutSideComponent';

const OutSideOfficeHoursForm = ({
  initialForm: InitialForm,
  sharedEmail,
  sharedName
}) => {
  // if (workTime()) return <InitialForm />;
  // return workTime() ? <InitialForm /> : <OutSideComponent />;
  return <InitialForm sharedEmail={sharedEmail} sharedName={sharedName} />;
};

export default OutSideOfficeHoursForm;
