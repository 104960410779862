import { checkAttendingTime } from './messages/checkAttendingTime';
import { checkBlockedIp } from './messages/checkBlockedIp';
import { identifierClientMessage } from './messages/identifierClientMessage';
import { triggerConfiguration } from './messages/triggerConfiguration';

/**
 * Configures initial websocket connection sending 4 messages to server that are included oin the domain use cases.
 * @param {WebSocket} instance - The websocket instance
 */
export const configureClientAfterConnection = instance => {
  checkBlockedIp(instance);
  checkAttendingTime(instance);
  identifierClientMessage(instance);
  triggerConfiguration(instance);
};
