import axios from 'axios';
import config from '../../utils/config';
import { retrieveMessagesFromServer } from './chat/retrieveMessagesFromServer';
import { hasNewChatFeature } from './hasNewChatFeature';

export function getMessage() {
  /**
   * Mensagem exibida no momento em que o cliente chega ao atendimento por humano.
   */

  const curHr = new Date().getHours();

  if (curHr < 12) {
    return 'Bom dia! Como podemos ajudá-lo(a)?';
  }
  if (curHr < 18) {
    return 'Boa tarde! Como podemos ajudá-lo(a)?';
  }
  return 'Boa noite! Como podemos ajudá-lo(a)?';
}

export function loadLinks() {
  /**
   * Atribui, a cada link dentro do chat, um "target" para abertura de links. Essa atribuição é feita no momento em que
   * a conversa é carregada
   */

  const linkOrigin = window.location.origin;
  const listLinks = document.getElementsByTagName('a');
  const links = Array.from(listLinks);
  links.forEach(item => {
    if (item.href.indexOf(linkOrigin) === -1) {
      item.setAttribute('target', '_blank');
    } else {
      item.setAttribute('target', '_top');
    }
  });
}

export function handleShowTextArea(show) {
  const form = document.querySelector('.rcw-sender');
  if (!form) return;
  form.style.display = show ? '' : 'none';
}

export function cleanSessionStorage() {
  /**
   * Limpeza do storage. Chamado em momentos em que o cliente sai da conversa.
   */
  sessionStorage.setItem('context_from', '');
  sessionStorage.setItem('context_to', '');
  sessionStorage.setItem('attendant_login', '');
  sessionStorage.setItem('hash_connection_chat', '');
  sessionStorage.setItem('attendant_to', '');
  sessionStorage.removeItem('opened_chat');
}

export function scrollMessage() {
  const message = document.querySelector('.rcw-messages-container');
  if (message) {
    message.scrollTop = message.scrollHeight;
  }
}

export function getIfChatIsOnline({ getConnection, setConnectedAlert }) {
  /**
   * Verifica se o chat está online a cada 10 segundos.
   */

  sessionStorage.setItem('connected', String(navigator.onLine));
  sessionStorage.removeItem('socket');

  setInterval(() => {
    if (sessionStorage.getItem('socket') === 'reload-new') {
      getConnection();
      setConnectedAlert('reload-new');
      sessionStorage.removeItem('socket');
    }

    if (!navigator.onLine && sessionStorage.getItem('connected') === 'true') {
      setConnectedAlert('disconnected');
      sessionStorage.setItem('connected', 'false');
    }

    if (navigator.onLine && sessionStorage.getItem('connected') === 'false') {
      sessionStorage.setItem('connected', 'true');
      getConnection();
      setConnectedAlert('waiting-reload');
    }
  }, 10000);
}

export async function handleStartChat({
  setSharedName,
  setSharedEmail,
  setSharedLastResponsible,
  renderStartChatSession,
  toggleChatWindow
}) {
  /**
   * Chamada quando o chat é carregado na tela do usuário. Busca saber se a conversa já existe ou não.
   * Se sim, renderiza o chat com a conversa salva no banco.
   */

  const hash = sessionStorage.getItem('hash_connection_chat');
  const openedChat = sessionStorage.getItem('opened_chat');
  if (!hash || !openedChat) return;

  if (hasNewChatFeature()) {
    const chat = await retrieveMessagesFromServer(hash);
    setSharedName(chat.customerName);
    setSharedEmail(chat.customerEmail);
    setSharedLastResponsible(chat.serviceAttendantName);
    renderStartChatSession(
      chat.marketplaceGenericMessages.map(
        ({ message, sender_type, metadata_of_uploaded_files }) => {
          const s3FileName = metadata_of_uploaded_files?.[0]?.s3_file_name;
          const fileName = metadata_of_uploaded_files?.[0]?.file_name;
          const urlRaw = s3FileName
            ? `${process.env.REACT_APP_ATTACHMENT_SUBDOMAIN}.s3.amazonaws.com/${s3FileName}`
            : null;

          return {
            text: message || urlRaw,
            name_attachment: fileName,
            url_anexo_raw: urlRaw,
            name: sender_type === 'client' ? chat.customerName : null,
            login: chat.serviceAttendantLogin
          };
        }
      )
    );
    toggleChatWindow();
  } else {
    axios.get(`${config.backend.url}existing-chat/${hash}`).then(response => {
      setSharedName(response.data.name);
      setSharedEmail(response.data.email);
      setSharedLastResponsible(response.data.lastConnectionIdResponsible);
      renderStartChatSession(response.data.messages);
      toggleChatWindow();
    });
  }
}

export function extractFileTypeFromString(string) {
  const REGEX = /(?:\.([^.]+))?$/;
  const fileExtension = REGEX.exec(string);

  return fileExtension[1];
}
export function submitAttachFile({ file, setSubmitAttachmentFile }) {
  if (file) {
    const reader = new FileReader();
    const base64file = [];
    reader.readAsDataURL(file);
    reader.onloadend = evt => {
      if (evt.target.readyState === FileReader.DONE) {
        base64file.push(evt.currentTarget.result);
      }

      const submitFile = {};
      submitFile.name = file.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      // eslint-disable-next-line prefer-destructuring
      submitFile.content = base64file[0].split(',')[1];
      setSubmitAttachmentFile(submitFile);
    };
  } else {
    setSubmitAttachmentFile('');
  }
}
