export const COLORS = {
  cardMessageBlue: '#6379f1',
  cardMessageGray: '#e0e0e0',
  cardMessageGreen: '#aed581',
  whiteText: '#fff',
  blackText: '#424242',
  lightGray: '#f5f5f5',
  greenText: '#80c343',
  btnPrimaryColor: '#007bff',
  btnSecondaryColor: '#89898b',
  btnSuccessColor: '#80c343',
  btnDangerColor: '#dc3545',
  btnWarningColor: '#ffc107',
  btnInfoColor: '#17a2b8',
  btnLightColor: '#e0e0e0',
  btnDarkColor: '#000000',
  btnLinkColor: '#007bff'
};
