import { getB2BPage } from '../../parentFunctions';
import { getHashConnection } from '../helpers/getHashConnection';

/**
 * Identifier message add the user as visitor in Acer systems.
 * @param {WebSocket} instance - The websocket instance
 */
export const identifierClientMessage = instance => {
  instance.send(
    JSON.stringify({
      type: 'identifier_client_message',
      data: {
        hashconnection: getHashConnection(),
        connection_channel: 'chat',
        business_type: getB2BPage() ? 'B2B' : 'B2C'
      }
    })
  );
};
