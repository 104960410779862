import React from 'react';
import { Box, makeStyles, Typography, Button } from '@material-ui/core';
import DOMPurify from 'dompurify';

const useStyles = makeStyles(() => ({
  buttonStepZero: {
    marginBottom: '2em',
    fontSize: 'inherit',
    width: '100%'
  },
  buttonText: {
    textTransform: 'none'
  }
}));

const BeforeAfterScreen = ({ handleResponseBot, botResponse }) => {
  const classes = useStyles();

  return (
    <Box paddingBottom={2}>
      <Button
        id="button-aftersale"
        className={classes.buttonStepZero}
        variant="outlined"
        onClick={() => handleResponseBot(`Sim`, false, botResponse?.message)}
      >
        <Typography
          className={classes.buttonText}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              `<strong>SIM</strong>,<br/>quero falar sobre minha compra`
            )
          }}
        />
      </Button>
      <Button
        id="button-beforesale"
        className={classes.buttonStepZero}
        variant="outlined"
        onClick={() => handleResponseBot(`Não`, false, botResponse?.message)}
      >
        <Typography
          className={classes.buttonText}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              `<strong>NÃO</strong>,<br/>mas, quero tirar dúvidas`
            )
          }}
        />
      </Button>
    </Box>
  );
};

export { BeforeAfterScreen };
