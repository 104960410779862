import moment from 'moment';
import ClientBubble from '../../../components/bubbles/ClientBubble.component';

export const onReceiveAttachmentMetadata = async (
  messageFromServer,
  renderCustomComponent
) => {
  const metadata = messageFromServer.data.metadata_of_uploaded_files;

  metadata.forEach(data => {
    renderCustomComponent(ClientBubble, {
      message: data.file_name,
      url: `${process.env.REACT_APP_ATTACHMENT_SUBDOMAIN}.s3.amazonaws.com/${data.s3_file_name}`,
      filename: data.file_name,
      timestamp: moment(),
      sender: sessionStorage.getItem('sharedName')
    });
  });
};
