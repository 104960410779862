import {
  Box,
  Button,
  Container,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { ThumbDown, ThumbUp } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import Axios from 'axios';
import React, { useState } from 'react';
import 'react-chat-jordan/lib/styles.css';
import config from '../../../utils/config';
import '../../ChatBox.css';
import { hasNewChatFeature } from '../../services/hasNewChatFeature';
import { sendSatisfactionSurvey } from '../../services/websocket/messages/sendSurvey';
import { getHashConnection } from '../../services/websocket/helpers/getHashConnection';
import { closeService } from '../../services/websocket/messages/closeService';

const useStyles = makeStyles(() => ({
  unmarked: {
    backgroundColor: 'gray !important'
  },
  cursor: {
    cursor: 'not-allowed'
  },
  secondaryColor: {
    backgroundColor: '#CCC !important',
    color: '#000 !important',
    '&:hover': {
      backgroundColor: '#d2d2d242 !important'
    }
  },
  disabled: {
    opacity: '0.5'
  }
}));

const EvaluateThisChat = ({
  goBackFunction,
  connection,
  hashConnectionChat,
  instance
}) => {
  const classes = useStyles();
  const [likeValue, setLikeValue] = useState(null);
  const [opinion, setOpinion] = useState('');

  const sendEvaluation = () => {
    if (hasNewChatFeature()) {
      sendSatisfactionSurvey(instance, {
        message: opinion,
        satisfied: likeValue,
        hashConnection: getHashConnection()
      });
      goBackFunction('', false, true);
      closeService(instance);
      instance.close();
      return;
    }

    const postData = {
      connection: String(connection),
      satisfactionScore: likeValue,
      opinion: String(opinion),
      hashConnection: hashConnectionChat
    };

    Axios.post(`${config.backend.url}satisfaction`, postData)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        goBackFunction('', false, true);
      });
  };

  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          <Typography variant="caption">
            Por favor, avalie o atendimento deste chat
          </Typography>
        </Box>
        <Box paddingTop={2}>
          <ToggleButtonGroup
            value={likeValue}
            exclusive
            onChange={(ev, newValue) => {
              if (newValue === null) {
                return;
              }
              if (likeValue !== newValue) {
                setLikeValue(newValue);
              }
            }}
          >
            <ToggleButton
              id="thumbs-up"
              className={!likeValue ? classes.unmarked : null}
              value
              aria-label="Thumbs Up"
            >
              <ThumbUp /> &nbsp; Gostei
            </ToggleButton>

            <ToggleButton
              id="thumbs-down"
              className={
                // eslint-disable-next-line no-nested-ternary
                likeValue
                  ? classes.unmarked
                  : likeValue === null
                  ? classes.unmarked
                  : null
              }
              value={false}
              aria-label="Thumbs Down"
            >
              <ThumbDown /> &nbsp; Não gostei
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        {likeValue === null && (
          <Box>
            <Typography variant="caption">Selecione uma opção acima</Typography>
          </Box>
        )}
        <Box width="100%" paddingTop={2}>
          <TextField
            id="input-evaluation"
            value={opinion}
            onChange={ev => {
              setOpinion(ev.target.value);
            }}
            variant="outlined"
            multiline
            minRows={6}
            fullWidth
            label="Deixe um comentário (opcional)"
            inputProps={{ maxLength: '500' }}
            helperText={`${opinion?.length}/500`}
          />
        </Box>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          paddingTop={2}
        >
          <Box paddingRight={2}>
            <Button
              id="button-cancel-evaluation"
              className={classes.secondaryColor}
              onClick={() => goBackFunction('', false, true)}
              variant="outlined"
              color="default"
            >
              Cancelar
            </Button>
          </Box>
          <Box>
            <Button
              id="button-send-evaluation"
              className={likeValue === null && classes.disabled}
              onClick={sendEvaluation}
              disabled={likeValue === null}
              variant="outlined"
            >
              Enviar
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default EvaluateThisChat;
