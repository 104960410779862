import AttendantBubble from '../../../components/bubbles/AttedantBubble.component';
import { playIframeAudio } from '../../parentFunctions';

export const onReceiveAttendantChange = (
  messageFromServer,
  renderCustomComponent
) => {
  renderCustomComponent(AttendantBubble, {
    message: `Você será atendido(a) agora pelo(a) analista ${messageFromServer.puller_attendant_nickname}`
  });
  playIframeAudio();
};
