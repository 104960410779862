/**
 * Chamadas para funções definidas no escopo da página web (PAI). As funções que serão executadas estão defindas
 * no arquivo ./public/chat-external.js
 */

export function playIframeAudio() {
  window.parent.postMessage(
    {
      type: 'play-audio'
    },
    `${window.parent.location.protocol}//${window.parent.location.host}/`
  );
}

export function openChatWindon() {
  window.parent.postMessage(
    {
      type: 'toggle-chat-window'
    },
    `${window.parent.location.protocol}//${window.parent.location.host}/`
  );

  // if (!window?.chatActivate) {
  //   window.chatActivate = true;
  //   window.parent.postMessage({ type: 'activate' },
  //     `${window.parent.location.protocol}//${window.parent.location.host}/`
  //   );
  // }

  // if (window?.chatActivate) {
  //   window.chatActivate = false;
  //   window.parent.postMessage({ type: 'desactivate' },
  //     `${window.parent.location.protocol}//${window.parent.location.host}/`
  //   );
  // }

  if (!window?.chatActivate) {
    window.parent.postMessage(
      { type: 'activate' },
      `${window.parent.location.protocol}//${window.parent.location.host}/`
    );
  }

  if (window?.chatActivate) {
    window.parent.postMessage(
      { type: 'desactivate' },
      `${window.parent.location.protocol}//${window.parent.location.host}/`
    );
  }
}

export function reloadIframe() {
  window.parent.postMessage(
    {
      type: 'reload-iframe'
    },
    `${window.parent.location.protocol}//${window.parent.location.host}/`
  );
}

export function reloadPage() {
  window.parent.location.reload(true);
}

export function getB2BPage() {
  const queryString = window.parent.location.search;
  const urlParams = new URLSearchParams(queryString);
  const b2b = urlParams.get('b2b');
  const urlB2B = window.parent.location.href;

  if (window.parent.document.title === 'B2B') {
    return true;
  }

  if (b2b === 'true') {
    return true;
  }

  if (
    urlB2B.includes('acerstoreb2b.myvtex.com') ||
    urlB2B.includes('acerempresas.com.br')
  ) {
    return true;
  }

  return false;
}
