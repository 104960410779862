import AttendantBubble from '../../../components/bubbles/AttedantBubble.component';

export const onReceiveQueuePosition = (
  messageFromServer,
  renderCustomComponent
) => {
  renderCustomComponent(AttendantBubble, {
    message: messageFromServer.message
  });
};
