/**
 * Tela anterior ao início do chat com o atendente.
 */

import React from 'react';
import { Box, Button, Divider, Typography, Grid } from '@material-ui/core';
import DOMPurify from 'dompurify';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import styled from 'styled-components';
import OutSideComponent from '../chatBox/OutSide.component';
import { chatHubSubmit } from '../../services/chatHubFunctions';

import { getB2BPage } from '../../services/parentFunctions';

const CustomTypography = styled(Typography)`
  font-size: 14px;
`;

const HintScreen = ({
  classes,
  handleResponseBot,
  botResponse,
  setHintScreenOpenState,
  renderStartChatSession,
  outSideHour,
  outSideHourResponse,
  // getMessage,
  // chatHubSubmit,
  nome,
  email,
  mensagem
  // setFlowType
}) => {
  return !outSideHour ? (
    <>
      <Box display="flex" flexDirection="column">
        <div className={classes.returnContainer}>
          <div id="link-back-hint" className={classes.returnIcon}>
            <KeyboardBackspaceIcon />{' '}
            <Typography onClick={() => setHintScreenOpenState(false)}>
              voltar
            </Typography>
          </div>
          <div className={classes.returnTitle} />
        </div>
        <Box mb={2}>
          <Divider className={classes.divider} />
        </Box>
        <CustomTypography
          variant="subtitle2"
          style={{ fontSize: '14px' }}
          className={classes.hintTitle}
        >
          Antes de começarmos, seguem algumas respostas para{' '}
          <strong>dúvidas frequentes:</strong>
        </CustomTypography>
        <Box mb={2}>
          <Divider className={classes.divider} />
        </Box>
        {!getB2BPage() ? (
          <>
            <CustomTypography className={classes.hintParagraphy}>
              1) Este chat é exclusivo para o atendimento à pessoa física.
            </CustomTypography>
            {/* <CustomTypography className={classes.hintParagraphy}>
              1) Se ja efetuou uma compra e possui chamado aberto, aguarde o
              prazo de até 24 horas (dias úteis) para resposta.
            </CustomTypography> */}
            <CustomTypography className={classes.hintParagraphy}>
              2) Não oferecemos nenhum tipo de <strong>desconto</strong> via
              esse canal de atendimento.
            </CustomTypography>
          </>
        ) : (
          <>
            <CustomTypography className={classes.hintParagraphy}>
              1) Este chat é exclusivo para atendimento para empresas. Para
              atendimentos à pessoa física acesse o link
              <a href="https://br-store.acer.com"> https://br-store.acer.com</a>
            </CustomTypography>
            <CustomTypography className={classes.hintParagraphy}>
              2) Se já efetuou uma compra e possui chamado aberto, aguarde o
              prazo de 24 horas (dias úteis) para resposta.
            </CustomTypography>
          </>
        )}

        <CustomTypography className={classes.hintParagraphy}>
          3) Trabalhamos apenas com a venda de{' '}
          <strong>produtos já montados</strong>, dessa forma não conseguimos
          fazer nenhuma alteração na configuração antes do envio, pois a caixa
          vem lacrada de fábrica.
        </CustomTypography>
        <CustomTypography className={classes.hintParagraphy}>
          4) Nosso <strong>melhor preço</strong> do produto, no momento,{' '}
          <strong>é o que está anunciado</strong>. Aproveite que a{' '}
          <strong>oferta é por tempo limitado</strong>.
        </CustomTypography>
        <Box mb={2}>
          <Divider className={classes.divider} />
        </Box>
        <CustomTypography variant="h6" className={classes.hintTitle}>
          Consegui te
          <strong> ajudar</strong>?
        </CustomTypography>
        <Grid container spacing={2} justifyContent="space-around">
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Button
              id="button-yes-hint"
              fullWidth
              className={classes.hintButtons}
              onClick={() => {
                handleResponseBot('Sim', false, botResponse?.message, true);
              }}
            >
              <CustomTypography
                className={classes.hintButtonsParagraphy}
                variant="h6"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    `<strong>SIM</strong>,<br/> obrigado`
                  )
                }}
              />
            </Button>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Button
              id="button-no-hint"
              fullWidth
              className={classes.hintButtons}
              onClick={() => {
                chatHubSubmit('prevenda', nome, email, mensagem);
                renderStartChatSession(
                  '',
                  'Estamos te encaminhando para um dos nossos especialistas.'
                );
                // handleResponseBot('Não', false, botResponse?.message);
              }}
            >
              <CustomTypography
                className={classes.hintButtonsParagraphy}
                variant="h6"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    `<strong>NÃO</strong>,<br/> quero falar com um atendente`
                  )
                }}
              />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  ) : (
    <OutSideComponent
      nome={nome}
      email={email}
      outSideHourResponse={outSideHourResponse}
    />
  );
};

export default HintScreen;
