import moment from 'moment';
import AttendantBubble from '../../../components/bubbles/AttedantBubble.component';

export const onReceiveAttendantAttachmentMetadata = async (
  messageFromServer,
  renderCustomComponent
) => {
  const metadata = messageFromServer.data.metadata_of_uploaded_files;

  // eslint-disable-next-line no-console
  renderCustomComponent(AttendantBubble, {
    message: messageFromServer.data.message,
    files: metadata.map(f => ({
      filename: f.file_name,
      url: `${process.env.REACT_APP_ATTACHMENT_SUBDOMAIN}.s3.amazonaws.com/${f.s3_file_name}`
    })),
    timestamp: moment()
  });
};
