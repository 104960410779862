import React from 'react';
import { Box, Typography, Button, makeStyles } from '@material-ui/core';
import DOMPurify from 'dompurify';

const useStyles = makeStyles(() => ({
  button: {
    width: '100%'
  }
}));

const BotButtons = ({ botResponse, handleResponseBot, button, index }) => {
  const classes = useStyles();

  return (
    <Box paddingBottom={2}>
      <Button
        id={`button-${index}`}
        className={classes.button}
        variant="outlined"
        onClick={() => handleResponseBot(button, false, botResponse?.message)}
      >
        <Typography
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(`${button}`)
          }}
        />
      </Button>
    </Box>
  );
};

export { BotButtons };
