import { getHashConnection } from '../helpers/getHashConnection';

/**
 * Send a message indicating to server that a user is typing or not a message.
 * @param {WebSocket} instance - The websocket instance.
 * @param {Object} payload - message payload.
 * @param {boolean} payload.isTyping - true or false.
 */
export const sendUserTypingMessage = (instance, { isTyping }) => {
  instance.send(
    JSON.stringify({
      type: 'typing_message',
      data: {
        publisher_hashconnection: getHashConnection(),
        is_typing: isTyping
      }
    })
  );
};
