/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { TextField, makeStyles, Grid } from '@material-ui/core';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import 'react-chat-jordan/lib/styles.css';
import './ChatBox.css';
import config from '../utils/config';
import { COLORS } from '../utils/colors.config';
import { divider, inputSubmitButton } from './Styles';
import BlockedBox from './BlockedBox';
import { handleShowTextArea, scrollMessage } from './services/chatFunctions';
import {
  submitOnHintScreen,
  generateRecaptchaToken,
  openTicketOnNewWindow,
  generatePayload
} from './services/chatHubFunctions';
import { reloadIframe, getB2BPage } from './services/parentFunctions';

import HintScreen from './components/chatAssistentBot/HintScreen.component';
import Form from './components/chatAssistentBot/Form.component';
import StartBot from './components/chatAssistentBot/StartBot.component';
import Load from './components/chatAssistentBot/Load.component';
import AttendantBubble from './components/bubbles/AttedantBubble.component';

import { B2BOpenTicket } from './components/chatAssistentBot/B2BOpenTicket.component';
import { updateClientInformation } from './services/chat/updateClientInformation';
import { hasNewChatFeature } from './services/hasNewChatFeature';

const useStyles = makeStyles(theme => ({
  stepZeroText: {
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: '400',
    margin: '2em 0em 2em 0em'
  },
  welcomeText: {
    display: 'flex',
    color: COLORS.greenText,
    justifyContent: 'center'
  },
  buttonStepZero: {
    marginBottom: '2em',
    fontSize: 'inherit',
    width: '100%'
  },
  buttonText: {
    textTransform: 'none'
  },
  button: {
    width: '100%'
  },
  returnContainer: {
    display: 'flex'
  },
  returnIcon: {
    display: 'flex',
    color: COLORS.greenText,
    width: '70px',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    cursor: 'pointer'
  },
  returnTitle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    textAlign: 'right',
    color: COLORS.blackText
  },
  divider,
  hintTitle: {
    color: COLORS.greenText,
    fontSize: '105%',
    marginBottom: '1em'
  },
  hintParagraphy: {
    marginBottom: '1em'
  },
  hintButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  hintButtonsParagraphy: {
    textTransform: 'none',
    fontSize: '1em',
    borderRadius: '0.8em'
  },
  hintButtons: {
    fontSize: '1em',
    height: '100%'
  },
  inputSubmitButton,
  logoAcerBubble: { marginTop: '5px', maxWidth: '20px' },
  buttonsContainer: {
    '& > *': {
      margin: '5px',
      width: '100%'
    }
  },
  disabled: {
    opacity: '0.5'
  }
}));

const ChatAssistantBot = ({
  renderStartChatSession,
  setSharedName,
  setSharedEmail,
  setSharedResponseBot,
  setSharedLastOption,
  setSharedLastBotResponse,
  setHumanChatInputOpenState,
  renderEndChatSession
}) => {
  const classes = useStyles();
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [responseBot, setResponseBot] = useState([]);

  const [botResponse, setBotResponse] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [userResponse, setUserResponse] = useState('');

  const [historyOfChathubRequests, setHistoryOfChathubRequests] = useState([]);
  const [hintScreenOpenState, setHintScreenOpenState] = useState(false);
  const [outSideHour, setOutSideHour] = useState(false);
  const [outSideHourResponse, setOutSideHourResponse] = useState(false);

  const [flowType, setFlowType] = useState('');

  const [isErrorRecaptcha, setIsErrorRecaptcha] = useState(false);
  const [json, setJSON] = useState(false);

  const [showB2BScreen, setShowB2BScreen] = useState(false);

  const appendHistoryList = newHistory => {
    setHistoryOfChathubRequests(prevHistoryList => [
      ...prevHistoryList,
      newHistory
    ]);
  };
  const removeLastFromHistory = newHistory => {
    if (historyOfChathubRequests.length >= 1) {
      const newState = [...historyOfChathubRequests];
      newState.pop();
      setHistoryOfChathubRequests(newState);
    }
  };

  const handleMessage = chatHubResponse => {
    /**
     * Renderiza a conversa com o atendente caso o cliente opte, dentro do chathub
     * falar com o atendente ou dê erro na hora de buscar o chat do cliente.
     */

    const { attendance, feedback, message, flow_type, step } = chatHubResponse;

    if (attendance) {
      handleShowTextArea(true);
      renderStartChatSession('', message);
      setHumanChatInputOpenState(true);
    }
    if (feedback) {
      renderEndChatSession(message);
    }
  };

  const submitRobotInfo = async () => {
    // this  variable is set in the parent page that creates the iframe programmatically by our chat-external script

    /**
     * Função de envio dos dados iniciais do chat. Chamado somente ao entrar no chathub.
     */

    // const recaptchToken = await generateRecaptchaToken();

    try {
      setIsLoading(true);
      // const postPayloadOfFirstRequest = {
      //   name: nome,
      //   email,
      //   message: mensagem,
      //   tag: '',
      //   accuracy: 1,
      //   step: 0,
      //   feedback: false,
      //   attendance: false,
      //   flow_type: flowType || '',
      //   recaptchToken,
      //   hash_connection_chat: sessionStorage.getItem('hash_connection_chat'),
      //   buttons: []
      // };

      const postPayloadOfFirstRequest = await generatePayload({
        nome,
        email,
        mensagem,
        flowType
      });

      await Axios.post(
        `${config.backend.ia_no_auth}lambda/chathub`,
        postPayloadOfFirstRequest
      ).then(response => {
        if (response.data.status_code === undefined) {
          appendHistoryList(postPayloadOfFirstRequest);
          setResponseBot([
            ...responseBot,
            ...[
              {
                option: '',
                tag: response.data.tag,
                nextmessage: response.data.message,
                hash_connection_chat: sessionStorage.getItem(
                  'hash_connection_chat'
                )
              }
            ]
          ]);
          setBotResponse(response.data);
        } else {
          setIsErrorRecaptcha(true);

          setTimeout(() => {
            reloadIframe();
          }, 2000);
        }
      });
    } catch (error) {
      handleMessage({ attendance: true });
    }

    setSharedName(nome);
    setSharedEmail(email);
    setIsLoading(false);

    if (hasNewChatFeature()) {
      try {
        updateClientInformation({
          hashconnection: sessionStorage.getItem('hash_connection_chat'),
          name: nome,
          email
        });
      } catch (error) {
        setTimeout(() => {
          reloadIframe();
        }, 2000);
      }
    }
  };

  const doChathubRequest = async (payload, option) => {
    /**
     * Faz as requisições do chatbot.
     */

    payload.recaptchToken = await generateRecaptchaToken();

    try {
      const response = await Axios.post(
        `${config.backend.ia_no_auth}lambda/chathub`,
        payload
      );
      setUserResponse('');
      setBotResponse(response.data);
      handleMessage(response.data);
      setIsLoading(false);

      setResponseBot([
        ...responseBot,
        ...[
          { option, tag: response.data.tag, nextmessage: response.data.message }
        ]
      ]);
      setSharedLastBotResponse(response.data);
      setSharedLastOption(option);
      setSharedResponseBot(responseBot);
    } catch (error) {
      handleMessage({ attendance: true });
    }
  };

  const handleResponseBot = async (
    option,
    overrideStepToOne,
    message,
    overrideStepToMinusOne
  ) => {
    /**
     * Envia as respostas para o chathub.
     */

    if (botResponse.step === 0 && option === 'Não' && !hintScreenOpenState) {
      /**
       * Quando o usuário optar por Não, na tela inicial do chat, renderiza a HintScreen,
       * e é enviado o payload abaixo para o chathub
       */

      await submitOnHintScreen(
        nome,
        email,
        mensagem,
        setHintScreenOpenState,
        setOutSideHour,
        setOutSideHourResponse
      );

      return;
    }

    setHintScreenOpenState(false);
    setIsLoading(true);

    // const postPayloadOfRequest = {
    //   ...botResponse,
    //   message: userResponse || botResponse?.message,
    //   flow_type: overrideStepToMinusOne ? 'prevenda' : botResponse.flow_type,
    //   step: overrideStepToOne
    //     ? 1
    //     : overrideStepToMinusOne
    //       ? -1
    //       : Number(botResponse.step),
    //   buttons: option ? [option] : [],
    //   hash_connection_chat: sessionStorage.getItem('hash_connection_chat')
    // };

    const postPayloadOfRequest = await generatePayload({
      botResponse,
      userResponse,
      overrideStepToMinusOne,
      overrideStepToOne,
      option
    });

    openTicketOnNewWindow(botResponse, option);
    doChathubRequest(postPayloadOfRequest, option);
    appendHistoryList(postPayloadOfRequest);
  };

  const returnToPreviousStep = async () => {
    /**
     * Função que volta para o passo anterior. Realiza o envio do passo anterior para voltar para tela.
     */

    if (botResponse.step === 0) {
      removeLastFromHistory();
      setBotResponse('');
      setHintScreenOpenState(false);
      return;
    }
    const historyOfChathubRequestsLength = historyOfChathubRequests.length;
    if (historyOfChathubRequestsLength > 1) {
      const previousPayload =
        historyOfChathubRequests[historyOfChathubRequestsLength - 2];
      doChathubRequest(previousPayload, previousPayload.buttons);
      removeLastFromHistory();
    }
  };

  useEffect(() => {
    scrollMessage();
    handleShowTextArea(false);
    setJSON({
      ip: localStorage.getItem('ip'),
      blocked: localStorage.getItem('blockedIP')
    });
  }, []);

  return json.blocked === 'true' ? (
    <BlockedBox ip={json.ip} />
  ) : !botResponse ? (
    <Form
      {...{
        isErrorRecaptcha,
        TextField,
        submitRobotInfo,
        nome,
        setNome,
        email,
        setEmail
      }}
    />
  ) : (
    <>
      {isLoading ? (
        <Load />
      ) : hintScreenOpenState ? (
        <HintScreen
          classes={classes}
          handleResponseBot={handleResponseBot}
          botResponse={botResponse}
          renderStartChatSession={renderStartChatSession}
          setHintScreenOpenState={setHintScreenOpenState}
          outSideHour={outSideHour}
          outSideHourResponse={outSideHourResponse}
          setFlowType={setFlowType}
          email={email}
          nome={nome}
          mensagem={mensagem}
        />
      ) : (
        <>
          {!showB2BScreen ? (
            <StartBot
              {...{
                returnToPreviousStep,
                historyOfChathubRequests,
                botResponse,
                handleResponseBot,
                showB2BScreen,
                setShowB2BScreen
              }}
            />
          ) : (
            <AttendantBubble message="A Acer agradece seu contato." />
            // <B2BOpenTicket />
            // <HintScreen
            //   classes={classes}
            //   handleResponseBot={handleResponseBot}
            //   botResponse={botResponse}
            //   renderStartChatSession={renderStartChatSession}
            //   setHintScreenOpenState={setHintScreenOpenState}
            //   outSideHour={outSideHour}
            //   outSideHourResponse={outSideHourResponse}
            //   setFlowType={setFlowType}
            //   email={email}
            //   nome={nome}
            //   mensagem={mensagem}
            // />
          )}
        </>
      )}
    </>
  );
};

export default ChatAssistantBot;
