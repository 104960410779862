import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Axios from 'axios';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import {
  Widget,
  deleteMessages,
  renderCustomComponent,
  toggleWidget
} from 'react-chat-jordan';
import 'react-chat-jordan/lib/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import config from '../utils/config';
import ChatAssistantBot from './ChatAssistantBot';
import './ChatBox.css';
import ChatConnection from './ChatConnection';
import OutSideOfficeHoursForm from './OutSideOfficeHoursForm';
import { divider, inputSubmitButton } from './Styles';
import AttendantBubble from './components/bubbles/AttedantBubble.component';
import ClientBubble from './components/bubbles/ClientBubble.component';
import EvaluateThisChat from './components/chatBox/EvaluateThisChat';

import {
  cleanSessionStorage,
  getIfChatIsOnline,
  getMessage,
  handleStartChat,
  loadLinks,
  submitAttachFile
} from './services/chatFunctions';
import { openChatWindon, reloadIframe } from './services/parentFunctions';
import { recieveMessage } from './services/recieveMessageFunctions';
import {
  handleSendMessage,
  sendInputTextNow
} from './services/sendMessageFunctions';
import { stopTrigger, trigger } from './services/triggerFunctions';

import ConfirmReload from './components/chatBox/ConfirmReload.component';
import ConfirmationBeforeEndChat from './components/chatBox/ConfirmationBeforeEndChat.component';
import EndChatSessionComponent from './components/chatBox/EndChatSessionComponent.component';
import GetCustomLauncher from './components/chatBox/GetCustomLauncher.component';
import HumanChatInput from './components/chatBox/HumanChatInput.component';
import OutSideComponent from './components/chatBox/OutSide.component';
import Title from './components/chatBox/Title.component';
import { hasNewChatFeature } from './services/hasNewChatFeature';
import { load } from './services/nicknameSlice';
import { addWebsocketListener } from './services/websocket/addWebsocketListener';
import { configureClientAfterConnection } from './services/websocket/configureClientAfterConnection';
import { createSocketInstance } from './services/websocket/createInstance';
import { closeService } from './services/websocket/messages/closeService';

const useStyles = makeStyles(() => ({
  titleIconTop: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  titleIconBottom: {
    display: 'flex',
    justifyContent: 'flex-start',
    cursor: 'pointer'
  },
  chatTitleContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '60px',
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  chatTitle: {
    width: '90%',
    lineHeight: '1.2em'
  },
  inputSubmitButton,
  divider,
  humanChatInputContainer: {
    padding: '0px 17px 10px 15px'
  },
  fileNameSpan: {
    borderRadius: '5px',
    width: '80%',
    padding: '8px',
    color: 'black',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: '12px',
    backgroundColor: '#cccccc'
  },
  redButton: {
    '&.MuiButtonBase-root': {
      color: 'white !important',
      backgroundColor: 'red !important'
    }
  }
}));

let socket = hasNewChatFeature() ? null : ChatConnection.getConnection();

const ChatBox = () => {
  const classes = useStyles();

  const [evaluatingChat, setEvaluatingChat] = useState(false);
  const [
    clientInteractedWithAttendant,
    setClientInteractedWithAttendant
  ] = useState(false);
  const [ticketId, setTicketId] = useState(0);
  const [from, setFrom] = useState(
    sessionStorage.getItem('context_from') || null
  );
  const [to, setTo] = useState(sessionStorage.getItem('context_to') || null);
  const [sharedName, setSharedName] = useState('');
  const [sharedEmail, setSharedEmail] = useState('');
  const [sharedLastResponsible, setSharedLastResponsible] = useState('');
  const [sharedResponseBot, setSharedResponseBot] = useState([]);
  const [sharedLastOption, setSharedLastOption] = useState([]);
  const [sharedLastBotResponse, setSharedLastBotResponse] = useState({});

  const dispatch = useDispatch();

  const [submitAttachmentFile, setSubmitAttachmentFile] = useState('');
  const [showLimitFile, setShowLimitFile] = useState(false);
  const [urlFile, setUrlFile] = useState('');
  const [urlAnexoRaw, setUrlAnexoRaw] = useState('');
  const [clientHasBeenDistributed, setClientHasBeenDistributed] = useState(
    false
  );

  const [humanChatUserInputValue, setHumanChatUserInputValue] = useState('');
  const [humanChatInputOpenState, setHumanChatInputOpenState] = useState(false);
  const [
    hashConnectionChatForEvaluation,
    setHashConnectionChatForEvaluation
  ] = useState(sessionStorage.getItem('hash_connection_chat'));
  const [connectedAlert, setConnectedAlert] = useState('');
  const [outOfHours, setOutOfHours] = useState(false);
  const [outOfHoursMessage, setOutOfHoursMessage] = useState('');

  const [loadFile, setLoadFile] = useState(false);

  const [instance, setInstance] = useState();

  const updateSharedNameLongsideLocalStorage = name => {
    setSharedName(name);
    sessionStorage.setItem('sharedName', name);
  };

  const clearMessages = () => {
    for (let i = 0; i < 999; i++) {
      deleteMessages(i);
    }
  };

  const renderConfirmationBeforeEndChat = () => {
    /**
     * Renderiza o componente para finalização do chat.
     */
    renderCustomComponent(AttendantBubble, {
      component: (
        <ConfirmationBeforeEndChat
          {...{
            clearMessages,
            setHumanChatInputOpenState,
            socket: hasNewChatFeature() ? instance : socket,
            setClientInteractedWithAttendant,
            setEvaluatingChat
          }}
        />
      ),
      timestamp: moment()
    });
  };

  const renderEndChatSession = (
    botMessage = '',
    kickOut = false,
    afterEvaluation = false
  ) => {
    if (botMessage) {
      clearMessages();
      setHumanChatInputOpenState(false);
      renderCustomComponent(AttendantBubble, { message: botMessage });
      renderCustomComponent(AttendantBubble, {
        component: <EndChatSessionComponent />,
        timestamp: moment()
      });
    }
    if (kickOut) {
      setClientInteractedWithAttendant(false);
      setEvaluatingChat(true);
    } else if (clientInteractedWithAttendant) {
      if (sessionStorage.getItem('hash_connection_chat') === null) {
        reloadIframe();
      } else {
        renderConfirmationBeforeEndChat();
      }
    } else if (!botMessage) {
      reloadIframe();
    }
    if (afterEvaluation) {
      cleanSessionStorage();
      reloadIframe();
    }
  };

  useEffect(() => {
    if (!hasNewChatFeature()) return;
    const newInstance = createSocketInstance();

    newInstance.onopen = () => {
      configureClientAfterConnection(newInstance);
      addWebsocketListener(
        newInstance,
        renderCustomComponent,
        renderEndChatSession,
        setClientHasBeenDistributed
      );
      setInstance(newInstance);
    };

    return () => {
      if (newInstance) {
        closeService(newInstance);
        newInstance.close();
      }
      setInstance();
    };
  }, []);

  const toggleChatWindow = () => {
    openChatWindon();
    toggleWidget();

    setInterval(() => {
      loadLinks();
    }, 1000);
  };

  const renderConfirmReload = () => {
    setHumanChatInputOpenState(false);
    renderCustomComponent(AttendantBubble, {
      component: (
        <ConfirmReload
          {...{
            clearMessages,
            socket,
            setClientInteractedWithAttendant
          }}
        />
      ),
      timestamp: moment()
    });
  };

  const sendMessage = sendFile => {
    handleSendMessage({
      socket: hasNewChatFeature() ? instance : socket,
      sharedResponseBot,
      sharedLastOption,
      sharedLastBotResponse,
      to,
      from,
      urlFile,
      humanChatUserInputValue,
      sendFile,
      sharedName,
      sharedEmail,
      sharedLastResponsible,
      urlAnexoRaw,
      renderConfirmReload,
      renderCustomComponent,
      setHumanChatUserInputValue,
      setUrlFile,
      setClientInteractedWithAttendant
    });
  };

  const getConnection = () => {
    socket = ChatConnection.getConnection(setConnectedAlert);

    if (!socket) {
      return;
    }

    socket.setOnMessageCallback(event => {
      recieveMessage({
        socket,
        event,
        renderCustomComponent,
        setClientInteractedWithAttendant,
        setOutOfHours,
        setOutOfHoursMessage,
        setHashConnectionChatForEvaluation,
        renderEndChatSession,
        setSharedName: updateSharedNameLongsideLocalStorage,
        setSharedEmail,
        setFrom,
        setTo
      });
    });
  };

  const { nicknames, storeUpdated } = useSelector(state => state.nicknames);

  const findNickname = search => {
    const findedNickname = nicknames.find(
      loadedNickname => loadedNickname.nickname === search
    );

    if (findedNickname) {
      return findedNickname.nickname;
    }

    return search;
  };

  function triggerChat(message, name) {
    /**
     * Função de abertura do chat via disparo do trigger. Será usado futuramente para a função
     * de chat ativo.
     */
    getConnection();
    clearMessages();
    renderCustomComponent(AttendantBubble, { message });

    if (!sharedName) {
      updateSharedNameLongsideLocalStorage(name);
    }

    setHumanChatInputOpenState(true);
    toggleChatWindow();
    stopTrigger();
  }

  const renderMessages = clientMessages => {
    return clientMessages?.forEach(item => {
      if (item.email || item.name) {
        renderCustomComponent(ClientBubble, {
          message: item?.text,
          filename: item.name_attachment,
          url: item.url_anexo_raw && item?.text,
          timestamp: item?.date,
          sender: item.name
        });

        setClientInteractedWithAttendant(true);
      } else {
        renderCustomComponent(AttendantBubble, {
          message: item?.text,
          url: item?.text,
          filename: item?.name_attachment,
          timestamp: item?.date,
          sender: findNickname(item.login)
        });
      }
      return item;
    });
  };

  const renderStartChatSession = (clientMessages, botMessage) => {
    getConnection();
    clearMessages();

    if (botMessage) {
      renderCustomComponent(AttendantBubble, { message: botMessage });
    }

    renderCustomComponent(AttendantBubble, { message: getMessage() });

    if (clientMessages) {
      renderMessages(clientMessages);
    }
    setHumanChatInputOpenState(true);
  };

  const InitialForm = () => {
    if (evaluatingChat)
      return (
        <EvaluateThisChat
          connection={from}
          instance={instance}
          hashConnectionChat={hashConnectionChatForEvaluation}
          ticketId={ticketId}
          goBackFunction={renderEndChatSession}
        />
      );

    if (outOfHours) {
      return (
        <OutSideComponent
          nome=""
          email=""
          outSideHourResponse={{ message: outOfHoursMessage }}
        />
      );
    }

    return (
      <ChatAssistantBot
        renderStartChatSession={renderStartChatSession}
        renderEndChatSession={renderEndChatSession}
        setTicketId={setTicketId}
        setEvaluatingChat={setEvaluatingChat}
        setSharedName={updateSharedNameLongsideLocalStorage}
        setSharedEmail={setSharedEmail}
        setSharedResponseBot={setSharedResponseBot}
        setSharedLastOption={setSharedLastOption}
        setSharedLastBotResponse={setSharedLastBotResponse}
        setHumanChatInputOpenState={setHumanChatInputOpenState}
        socket={socket}
      />
    );
  };

  const renderInitialForm = () => {
    clearMessages();
    renderCustomComponent(OutSideOfficeHoursForm, { initialForm: InitialForm });
  };

  const submitAttachment = file => {
    if (file && file.size > 2097152) {
      setShowLimitFile(true);
    } else {
      setShowLimitFile(false);
      submitAttachFile({ file, setSubmitAttachmentFile });
    }
  };

  const handleSubmitFile = () => {
    if (hasNewChatFeature()) {
      return sendMessage(true);
    }
    const environmentS3Url = process.env.REACT_APP_ATTACHMENT_POST_URL;
    setLoadFile(true);
    Axios.post(
      `${environmentS3Url}/no-auth/ms-chat/send-attachment`,
      submitAttachmentFile
    )
      .then(response => {
        setUrlAnexoRaw(
          `${process.env.REACT_APP_ATTACHMENT_SUBDOMAIN}.s3.amazonaws.com/${response.data.key}`
        );
        setUrlFile(response.data);
      })
      .catch(err => {
        // eslint-disable-next-line
        console.log(err);
      })
      .finally(() => {
        setLoadFile(false);
        setSubmitAttachmentFile('');
      });
  };

  useEffect(() => {
    if (urlFile) {
      sendMessage(true);
    }
    // eslint-disable-next-line
  }, [urlFile]);

  useEffect(() => {
    renderInitialForm();
    setHumanChatInputOpenState(false);
    // eslint-disable-next-line
  }, [evaluatingChat, outOfHours]);

  const decryptNicknames = fetchedNicknames => {
    const encodedKey = CryptoJS.enc.Base64.parse(
      process.env.REACT_APP_AES_ENCRYPT_KEY
    );

    const cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(fetchedNicknames.data)
    });

    const decryptedData = CryptoJS.AES.decrypt(cipherParams, encodedKey, {
      mode: CryptoJS.mode.ECB
    });

    return JSON.parse(CryptoJS.enc.Utf8.stringify(decryptedData));
  };

  const loadNicknames = async () => {
    const fetchData = async () => {
      const fetchedNicknames = await Axios.get(
        `${config.cognito.noAuthUrl}/find-users-nickname-encrypted`
      );

      const dataLoaded = decryptNicknames(fetchedNicknames);
      dispatch(load(dataLoaded));
    };

    // Date difference
    const diffTime = Math.abs(new Date() - storeUpdated);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    /**
     * if nicknames list is empty or does not exist, fetch data from server and
     * loads this data on store
     */

    const storageNicknames = localStorage.getItem('persist:root');

    if (!storageNicknames && (nicknames?.length === 0 || !nicknames)) {
      fetchData();
    }

    if (storageNicknames && nicknames?.length === 0) {
      fetchData();
    }

    /**
     * if nicknames list exists, checks the date this list was updated. If this
     * updated date is more than 3 days from today, fetch data from server and refresh the store
     */
    if (diffDays > 3) {
      fetchData();
    }
  };

  useEffect(() => {
    const triggerInfo = JSON.parse(localStorage.getItem('triggerInfo'));

    if (triggerInfo) {
      trigger(
        triggerChat,
        triggerInfo?.isActive,
        triggerInfo?.timeFirstTrigger,
        triggerInfo?.timeSecondTrigger,
        triggerInfo?.messageClient
      );
      handleStartChat({
        setSharedName: updateSharedNameLongsideLocalStorage,
        setSharedEmail,
        setSharedLastResponsible,
        renderStartChatSession,
        toggleChatWindow
      });
    }
    // eslint-disable-next-line
  }, [localStorage.getItem('triggerInfo')]);

  useEffect(() => {
    renderInitialForm();
    getIfChatIsOnline({ getConnection, setConnectedAlert });

    loadNicknames();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="ChatBox">
      <Widget
        title={
          <Title
            {...{
              classes,
              toggleChatWindow,
              setHashConnectionChatForEvaluation,
              clientInteractedWithAttendant,
              renderEndChatSession
            }}
          />
        }
        showCloseButton={false}
        fullScreenMode
        subtitle=""
        senderPlaceHolder="Mensagem"
        // handleNewUserMessage={() => { }}
        launcher={() => <GetCustomLauncher {...{ toggleChatWindow }} />}
        textArea
        showTimeStamp
        inputComponent={
          <HumanChatInput
            {...{
              socket: hasNewChatFeature() ? instance : socket,
              classes,
              humanChatInputOpenState,
              evaluatingChat,
              connectedAlert,
              submitAttachment,
              submitAttachmentFile,
              humanChatUserInputValue,
              setHumanChatUserInputValue,
              sendInputTextNow,
              sendMessage,
              loadFile,
              handleSubmitFile,
              showLimitFile,
              sharedName,
              clientHasBeenDistributed
            }}
          />
        }
      />
    </div>
  );
};

export default ChatBox;
