import { getB2BPage } from '../../parentFunctions';
import { getHashConnection } from '../helpers/getHashConnection';

/**
 * Check acer store attending time.
 * @param {WebSocket} instance - The websocket instance
 */
export const checkAttendingTime = instance => {
  instance.send(
    JSON.stringify({
      type: 'attending_time',
      data: {
        hashconnection: getHashConnection(),
        connection_channel: 'chat',
        business_type: getB2BPage() ? 'B2B' : 'B2C'
      }
    })
  );
};
