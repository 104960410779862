/**
 * Componente de inicialização do chat por chatBot ou chat com atendente (Pós e pré-venda); Renderiza as respostas do bot.
 */
import React from 'react';

import { Box, makeStyles, Typography, Divider, Grid } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import DOMPurify from 'dompurify';
import { divider, inputSubmitButton } from '../../Styles';
import profileImage from '../../../assets/images/acer_ico.png';
import { COLORS } from '../../../utils/colors.config';
import { BeforeAfterScreen } from './BeforeAfterScreen.component';
import { BotButtons } from './BotButtons.component';

const useStyles = makeStyles(() => ({
  stepZeroText: {
    textAlign: 'center',
    fontSize: '1.2rem',
    fontWeight: '400',
    margin: '2em 0em 2em 0em'
  },
  welcomeText: {
    display: 'flex',
    color: COLORS.greenText,
    justifyContent: 'center'
  },
  buttonStepZero: {
    marginBottom: '2em',
    fontSize: 'inherit',
    width: '100%'
  },
  buttonText: {
    textTransform: 'none'
  },
  button: {
    width: '100%'
  },
  returnContainer: {
    display: 'flex'
  },
  returnIcon: {
    display: 'flex',
    color: COLORS.greenText,
    width: '70px',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    cursor: 'pointer'
  },
  returnTitle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    textAlign: 'right',
    color: COLORS.blackText
  },
  divider,
  hintTitle: {
    color: COLORS.greenText,
    fontSize: '105%',
    marginBottom: '1em'
  },
  hintParagraphy: {
    marginBottom: '1em'
  },
  hintButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  hintButtonsParagraphy: {
    textTransform: 'none',
    fontSize: '1em',
    borderRadius: '0.8em'
  },
  hintButtons: {
    fontSize: '1em',
    height: '100%'
  },
  inputSubmitButton,
  logoAcerBubble: { marginTop: '5px', maxWidth: '20px' },
  buttonsContainer: {
    '& > *': {
      margin: '5px',
      width: '100%'
    }
  },
  disabled: {
    opacity: '0.5'
  }
}));

const StartBot = ({
  returnToPreviousStep,
  historyOfChathubRequests,
  botResponse,
  handleResponseBot
  // setShowB2BScreen
  // showB2BScreen
}) => {
  const classes = useStyles();

  return (
    <Box
      width="100%"
      height="100%"
      justifyContent="space-between"
      display="flex"
      flexDirection="column"
    >
      <div>
        <div className={classes.returnContainer}>
          <div id="link-back" className={classes.returnIcon}>
            <KeyboardBackspaceIcon />{' '}
            <Typography onClick={returnToPreviousStep}>voltar</Typography>
          </div>
          <div className={classes.returnTitle}>
            {historyOfChathubRequests.length > 2 &&
              !['geral', 'encerramento'].includes(botResponse.tag) &&
              historyOfChathubRequests[historyOfChathubRequests.length - 1]
                .buttons[0]}
          </div>
        </div>
        <Box mb={2}>
          <Divider className={classes.divider} />
        </Box>
        {botResponse?.message && (
          <Box paddingBottom={1}>
            {botResponse.step === 0 ? (
              <Typography className={classes.stepZeroText}>
                Você já <strong>efetuou sua compra?</strong>
              </Typography>
            ) : (
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <img
                      alt="logo acer"
                      className={classes.logoAcerBubble}
                      src={profileImage}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography
                      className="exercise-dialogue-text"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(`${botResponse?.message}`)
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
              </div>
            )}
          </Box>
        )}
        <Box
          mt={2}
          className={
            (botResponse.buttons[0] === 'Sim' &&
              botResponse.step !== 0 &&
              classes.buttonsContainer) ||
            ''
          }
          display="flex"
          flexDirection={
            botResponse.buttons[0] === 'Sim' && botResponse.step !== 0
              ? 'row'
              : 'column'
          }
        >
          {botResponse.step === 0 ? (
            // <B2BScree
            //   {...{ botResponse, handleResponseBot, setShowB2BScreen }}
            //   />
            <BeforeAfterScreen {...{ botResponse, handleResponseBot }} />
          ) : (
            <>
              {botResponse.step === 0 ? (
                <BeforeAfterScreen {...{ botResponse, handleResponseBot }} />
              ) : (
                <>
                  {botResponse.buttons.map((button, index) => {
                    return (
                      <BotButtons
                        {...{ botResponse, handleResponseBot, button, index }}
                        key={button}
                      />
                    );
                  })}
                </>
              )}
            </>
          )}
        </Box>
      </div>
    </Box>
  );
};

export default StartBot;
