import uuid from 'react-uuid';
import { hasNewChatFeature } from '../../hasNewChatFeature';
import { createProtocolHash } from './createProtocolHash';

export const getHashConnection = () => {
  const localHashConnection = sessionStorage.getItem('hash_connection_chat');
  if (localHashConnection) return localHashConnection;

  const newHashConnection = hasNewChatFeature()
    ? createProtocolHash()
    : new Date().getTime() + uuid();

  sessionStorage.setItem('hash_connection_chat', newHashConnection);

  return newHashConnection;
};
