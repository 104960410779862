import axios from 'axios';
import config from '../../../utils/config';

/**
  @param {string} hashConnection - user hashconnection
*/
export const retrieveMessagesFromServer = async hashConnection => {
  const response = await axios.get(
    `${config.backend.whatsappApi}/chat/${hashConnection}`
  );

  return response.data;
};
