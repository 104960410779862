import { localStorageKeysEnum } from '../../../../utils/applicationKeys';

export const onReceiveIpBlockNotification = message => {
  localStorage.setItem(
    localStorageKeysEnum.BLOCKED_IP,
    message.action === 'CLIENT_NOT_ALLOWED'
  );

  localStorage.setItem(localStorageKeysEnum.CURRENT_IP, message.client_ip);
};
