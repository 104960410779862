/**
 * Componente de envio de mensagens do chat do cliente.
 */

import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import React, { useState } from 'react';
import { getB2BPage, reloadPage } from '../../services/parentFunctions';
import { sendViewedText } from '../../services/sendMessageFunctions';

import AlertConnectionError from '../alerts/AlertConnectionError.component';
import UploadFileSection from './UploadFileSection.component';

import { DisabledButton } from '../../Styles';
import { extractFileTypeFromString } from '../../services/chatFunctions';
import { hasNewChatFeature } from '../../services/hasNewChatFeature';
import { sendMessageWithAttachments } from '../../services/websocket/messages/sendMessageWithAttachments';
import { sendUserTypingMessage } from '../../services/websocket/messages/sendUserIsTypingMessage';

const HumanChatInput = ({
  socket,
  // messageViewed,
  classes,
  humanChatInputOpenState,
  evaluatingChat,
  // sendViewedText,
  connectedAlert,
  // setClientFile,
  submitAttachment,
  submitAttachmentFile,
  humanChatUserInputValue,
  setHumanChatUserInputValue,
  sendInputTextNow,
  sendTyping,
  sendMessage,
  loadFile,
  handleSubmitFile,
  showLimitFile,
  clientHasBeenDistributed
}) => {
  const isEmpty = str => !str.trim().length;

  const [currentFile, setCurrentFile] = useState();

  const blobToDataUrl = blob =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });

  const handleSendMessageWithAttachment = async () => {
    if (!sessionStorage.getItem('opened_chat')) {
      sessionStorage.setItem('opened_chat', true);
    }

    const dataURL = await blobToDataUrl(currentFile);
    const base64 = dataURL.slice(dataURL.indexOf(',') + 1);

    const fileExtension = extractFileTypeFromString(currentFile.name);

    if (!fileExtension) {
      submitAttachment('');
      setCurrentFile('');
      return;
    }

    sendMessageWithAttachments(socket, {
      business_type: getB2BPage() ? 'B2B' : 'B2C',
      attached_files: [
        {
          name: currentFile.name,
          base64_content: base64,
          type: fileExtension
        }
      ]
    });

    submitAttachment('');
    setCurrentFile('');
  };

  const [timeoutId, setIntervalId] = useState();
  const [isTyping, setIsTyping] = useState(false);
  const handleInputChange = event => {
    const isInstanceOfWebsocket = socket instanceof WebSocket;

    if (!isInstanceOfWebsocket || !clientHasBeenDistributed) {
      return setHumanChatUserInputValue(event.target.value);
    }

    if (!isTyping) {
      setIsTyping(true);
      sendUserTypingMessage(socket, { isTyping: true });
    }

    const id = setTimeout(() => {
      sendUserTypingMessage(socket, { isTyping: false });
      setIsTyping(false);
    }, 1000);

    clearTimeout(timeoutId);
    setIntervalId(id);

    setHumanChatUserInputValue(event.target.value);
  };

  const isSendAttachmentButtonEnabled = () => {
    const baseCondition =
      !loadFile &&
      submitAttachmentFile &&
      connectedAlert === '' &&
      !showLimitFile;

    if (hasNewChatFeature()) {
      return baseCondition && currentFile;
    }

    return baseCondition;
  };

  return humanChatInputOpenState && !evaluatingChat ? (
    <div
      style={{
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        paddingTop: '20px'
      }}
      onMouseEnter={() => sendViewedText(socket)}
    >
      {showLimitFile && (
        <Box>
          <Typography variant="small" style={{ fontSize: '10px' }}>
            Tamanho do arquivo deve ser até 2 megabytes.
          </Typography>
        </Box>
      )}
      <Box className={classes.humanChatInputContainer}>
        {(connectedAlert === 'disconnected' ||
          connectedAlert === 'reload-new') && (
          <AlertConnectionError type="error" {...{ reloadPage }} />
        )}
        {connectedAlert === 'waiting-reload' && (
          <AlertConnectionError type="warning" {...{ reloadPage }} />
        )}
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item xs={9}>
            <UploadFileSection
              {...{
                socket,
                // messageViewed,
                classes,
                // setClientFile,
                submitAttachment: file => {
                  if (hasNewChatFeature()) {
                    setCurrentFile(file);
                  }
                  submitAttachment(file);
                },
                submitAttachmentFile,
                sendViewedText
              }}
            />
          </Grid>

          <Grid item xs={3}>
            {isSendAttachmentButtonEnabled() ? (
              <Button
                id="button-send-file"
                color="primary"
                variant="contained"
                size="small"
                style={{ width: '100%' }}
                onClick={() => {
                  if (hasNewChatFeature()) {
                    handleSendMessageWithAttachment();
                    return;
                  }

                  sendViewedText(socket);
                  handleSubmitFile(true);
                }}
              >
                Enviar anexo
              </Button>
            ) : (
              <DisabledButton
                color="primary"
                variant="contained"
                size="small"
                style={{ width: '100%' }}
                disabled
              >
                Enviar anexo
              </DisabledButton>
            )}
          </Grid>
        </Grid>
        <Grid container alignItems="flex-start" spacing={2}>
          <Grid item lg={9} md={9} sm={9} xs={9}>
            <TextField
              id="input-to-specialist"
              label="Digite sua mensagem"
              multiline
              fullWidth
              minRows={3}
              value={humanChatUserInputValue}
              onChange={handleInputChange}
              onFocus={() => {
                sendViewedText(socket);
              }}
              variant="outlined"
              onKeyDown={e => {
                sendInputTextNow(socket, sendTyping);

                if (e?.keyCode === 13 && !e.shiftKey && connectedAlert === '') {
                  e.preventDefault();
                  if (!isEmpty(humanChatUserInputValue)) {
                    sendMessage(false);
                    sendViewedText(socket);
                  } else {
                    setHumanChatUserInputValue('');
                  }

                  const isInstanceOfWebsocket = socket instanceof WebSocket;
                  if (isInstanceOfWebsocket && clientHasBeenDistributed) {
                    sendUserTypingMessage(socket, { isTyping: false });
                    clearTimeout(timeoutId);
                  }
                }
              }}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={3}>
            {!isEmpty(humanChatUserInputValue) && connectedAlert === '' ? (
              <Button
                id="button-send-message"
                className={classes.inputSubmitButton}
                fullWidth
                color="primary"
                variant="outlined"
                disableElevation
                disabled={isEmpty(humanChatUserInputValue)}
                onClick={() => sendMessage(false)}
              >
                <SendIcon />
              </Button>
            ) : (
              <DisabledButton
                className={classes.inputSubmitButton}
                fullWidth
                color="primary"
                variant="outlined"
                disableElevation
                disabled
              >
                <SendIcon />
              </DisabledButton>
            )}
          </Grid>
        </Grid>

        {/* <Grid container alignItems="flex-end" style={{ justifyContent: 'flex-end' }}>
            <Tooltip title="Encerrar" placement="top">
              <CustomButton
                onClick={() => {
                  setHashConnectionChatForEvaluation(
                    sessionStorage.getItem('hash_connection_chat')
                  );
                  socket.sendDisconnect();
                  cleanSessionStorage();
                  renderEndChatSession();
                }}
                size="small"
              >
                <ExitToAppIcon />
              </CustomButton>
            </Tooltip>
          </Grid> */}
      </Box>
    </div>
  ) : (
    <></>
  );
};

export default HumanChatInput;
