import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import nicknameReducer from '../ChatBox/services/nicknameSlice';

const persistConfig = {
  key: 'root',
  storage,
  transforms: [
    encryptTransform({
      secretKey: 'my-super-secret-key',
      onError(error) {
        // eslint-disable-next-line
        console.log(error);
      }
    })
  ]
};

const persistedReducer = persistReducer(persistConfig, nicknameReducer);

export const store = configureStore({
  reducer: {
    nicknames: persistedReducer
  },
  middleware: [thunk]
});

export const persistor = persistStore(store);
