import { onReceiveAttachmentMetadata } from './strategies/onReceiveAttachmentMetadata';
import { onReceiveAttendantAttachmentMetadata } from './strategies/onReceiveAttendantAttachmentMetadata';
import { onReceiveAttendantChange } from './strategies/onReceiveAttendantChange';
import { onReceiveAttendantClosingChat } from './strategies/onReceiveAttendantClosingChat';
import { onReceiveAttendantMessage } from './strategies/onReceiveAttendantMessage';
import { onReceiveDistributionNotification } from './strategies/onReceiveDistributionNotification';
import { onReceiveIpBlockNotification } from './strategies/onReceiveIpBlockNotification';
import { onReceiveQueuePosition } from './strategies/onReceiveQueuePosition';
import { onReceiveTriggerConfiguration } from './strategies/onReceiveTriggerConfiguration';

/**
 * Configures the websocket message listener.
 * @param {WebSocket} instance - The websocket instance.
 * @param {(component: ElementType, props: any) => void} renderCustomComponent - Responsible to render the attendant and/or custumer chats.
 * @param {(props: { botMessage: string, kickout: boolean, afterEvaluation: boolean }) => void} renderEndChatSession - Responsible to render the end of chat.
 * @param {(params: boolean) => void} setClientHasBeenDistributed - Indicates if the client has been distributed to an attendant.
 */

export const addWebsocketListener = (
  instance,
  renderCustomComponent,
  renderEndChatSession,
  setClientHasBeenDistributed
) => {
  instance.addEventListener('message', message => {
    const dataFromServer = JSON.parse(message.data);
    switch (dataFromServer.action) {
      case 'CLIENT_ALLOWED':
        onReceiveIpBlockNotification(dataFromServer);
        break;
      case 'CLIENT_NOT_ALLOWED':
        onReceiveIpBlockNotification(dataFromServer);
        break;
      case 'CLIENT_TRIGGER_CONFIGURATION':
        onReceiveTriggerConfiguration(dataFromServer);
        break;
      case 'CLIENT_QUEUE_POSITION':
        onReceiveQueuePosition(dataFromServer, renderCustomComponent);
        break;
      case 'CLIENT_DISTRIBUTED':
        onReceiveDistributionNotification(
          dataFromServer,
          renderCustomComponent
        );
        setClientHasBeenDistributed(true);
        break;
      case 'ATTENDANT_MESSAGE':
        onReceiveAttendantMessage(dataFromServer, renderCustomComponent);
        break;
      case 'ATTENDANT_CLOSING':
        onReceiveAttendantClosingChat(renderEndChatSession);
        break;
      case 'CLIENT_SEND_ATTACHMENT':
        onReceiveAttachmentMetadata(dataFromServer, renderCustomComponent);
        break;
      case 'CLIENT_RECEIVE_ATTACHMENT':
        onReceiveAttendantAttachmentMetadata(
          dataFromServer,
          renderCustomComponent
        );
        break;
      case 'ATTENDANT_CHANGED':
        onReceiveAttendantChange(dataFromServer, renderCustomComponent);
        break;
      default:
        break;
    }
  });
};
