import { getHashConnection } from '../helpers/getHashConnection';

/**
 * send customer message to the attendant who taken the service.
 * @param {Object} sendMessageParams - Parameters of sendMessage function.
 * @param {WebSocket} sendMessageParams.instance - The websocket instance.
 * @param {string} sendMessageParams.message - User message to attendant.
 */
export const sendMessage = ({ instance, message }) => {
  instance.send(
    JSON.stringify({
      type: 'send_client_message',
      data: {
        publisher_hashconnection: getHashConnection(),
        message
      }
    })
  );
};
