import { getB2BPage } from '../../parentFunctions';
import { getHashConnection } from '../helpers/getHashConnection';

/**
 * send client info to websocket to get trigger configuration, the trigger is a mechanic to able ative communication between attendant and customer.
 * @param {WebSocket} instance - The websacket instance
 */

export const triggerConfiguration = instance => {
  instance.send(
    JSON.stringify({
      type: 'trigger_configuration',
      data: {
        hashconnection: getHashConnection(),
        connection_channel: 'chat',
        business_type: getB2BPage() ? 'B2B' : 'B2C'
      }
    })
  );
};
