const addZeros = (value, length) => {
  return value
    .toString()
    .padStart(length, '0')
    .slice(-length);
};

export const createProtocolHash = () => {
  const date = new Date();
  const yyyy = date.getFullYear();
  const MM = addZeros(date.getMonth() + 1, 2);
  const dd = addZeros(date.getDate() + 1, 2);
  const HH = addZeros(date.getHours(), 2);
  const mm = addZeros(date.getMinutes(), 2);
  const SS = addZeros(date.getSeconds(), 2);
  const fff = addZeros(date.getMilliseconds(), 3);
  const RRRR = addZeros(Math.floor(Math.random() * 9999), 4);

  const hashConnection = `${yyyy}${MM}${dd}${HH}${mm}${SS}${fff}${RRRR}`;

  return hashConnection;
};
