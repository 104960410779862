import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
// import GetAppIcon from '@material-ui/icons/GetApp';
// import { Typography } from '@material-ui/core';
import { COLORS } from '../../../utils/colors.config';
import customerProfileIcon from '../../../assets/images/customerProfileIcon.png';

const Container = styled.div`
  display: flex; 
  width: 100%;
  justify-content: flex-end;
}
`;

const Logo = styled.div`
  img {
    width: 30px;
    height: 30px;
    opacity: 0.2;
  }
  display: flex;
  flex-direction: column;
  font-size: 10px;
  align-items: center;
`;

const Bubble = styled.div`
  background-color: ${COLORS.cardMessageGreen};
  color: #000;
  padding: 15px 25px;
  border-radius: 10px;
  margin-right: 10px;
  white-space: pre-line;
  max-width: 74%;
  // word-break: break-all;
`;

const Timestamp = styled.div`
  margin-top: 4px;
`;

const Sender = styled.div`
  color: #89898b;
  font-size: 13px;
  text-align: right;
`;

function urlify(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, url => {
    return `<a target="_blank" href="${url}" title="${url}" rel="noopener noreferrer" style="word-break: break-all">${url}</a>`;
  });
}

const ClientBubble = ({ message, filename, url, timestamp, sender = '' }) => {
  const messages = message?.split('\n');
  return (
    <Container>
      <Bubble>
        {filename && filename !== 'false' ? (
          <p>
            <a
              target="_blank"
              href={url}
              title={url}
              rel="noopener noreferrer"
              style={{ wordBreak: 'break-all' }}
            >
              {filename}
            </a>
          </p>
        ) : (
          <>
            {messages?.map(item => (
              // item.length > 0 && (
              //   <p key={item} style={{ marginTop: '0px' }}>
              //     {searchUrlInString(item)}
              //   </p>
              // )
              <p
                key={item}
                style={{ marginTop: '0px' }}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: urlify(item) }}
              />
            ))}
          </>
        )}
        <Sender>{sender}</Sender>
      </Bubble>
      <Logo>
        <img src={customerProfileIcon} alt="attendant logo" />
        <Timestamp>{timestamp && moment(timestamp).format('HH:mm')}</Timestamp>
      </Logo>
    </Container>
  );
};

export default ClientBubble;
