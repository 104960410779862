/**
 * Formulário de abertura do chat
 */
import React from 'react';
import {
  Box,
  Divider,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import FormHelper from '../../../utils/FormHelper';
import { COLORS } from '../../../utils/colors.config';

import { divider } from '../../Styles';

const useStyles = makeStyles(() => ({
  welcomeText: {
    display: 'flex',
    color: COLORS.greenText,
    justifyContent: 'center'
  },
  divider,
  disabled: {
    opacity: '0.5'
  }
}));

const Form = ({
  isErrorRecaptcha,
  TextField,
  submitRobotInfo,
  nome,
  setNome,
  email,
  setEmail
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" width="100%" flexDirection="column">
      <Box mb={2}>
        <Divider
          className={classes.divider}
          style={{ marginBlockStart: '12px' }}
        />
      </Box>
      <Box>
        <Typography variant="h6" className={classes.welcomeText}>
          Seja bem vindo à
        </Typography>
        <Typography variant="h6" className={classes.welcomeText}>
          Loja oficial da Acer! :)
        </Typography>
      </Box>

      {isErrorRecaptcha && (
        <Box mt={2} mb={3}>
          Houve um problema ao se conectar com o chat neste momento. Por favor,
          tente novamente em instantes.
        </Box>
      )}

      <Box mt={2} mb={3}>
        <Divider className={classes.divider} />
      </Box>
      <Box paddingBottom={2}>
        <TextField
          id="input-name"
          value={nome}
          onChange={ev => setNome(ev.target.value)}
          variant="outlined"
          fullWidth
          label="Qual é o seu nome?"
        />
      </Box>
      <Box paddingBottom={2}>
        <TextField
          id="input-email"
          value={email}
          onChange={ev => setEmail(ev.target.value)}
          variant="outlined"
          fullWidth
          label="E o seu e-mail?"
        />
      </Box>
      <Box>
        <Button
          id="button-continue"
          disabled={!nome || !FormHelper.validateEmail(email)}
          className={
            !nome || !FormHelper.validateEmail(email) ? classes.disabled : ''
          }
          onClick={submitRobotInfo}
          fullWidth
          variant="contained"
          size="large"
          color="primary"
          endIcon={<SendIcon />}
        >
          Continuar
        </Button>
      </Box>
    </Box>
  );
};

export default Form;
