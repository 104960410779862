import { getHashConnection } from '../helpers/getHashConnection';

/**
 * send customer message to the attendant who taken the service.
 * @param {WebSocket} instance - The websocket instance.
 * @param {Object} payload - User message to attendant.
 * @param {string} payload.message - User message to attendant.
 * @param {string} payload.business_type - B2C OR B2B
 * @param {Array<{ name: string; type: string; base64_content: string; }>} payload.attached_files - Files that go with message
 */
export const sendMessageWithAttachments = (
  instance,
  { message, attached_files, business_type }
) => {
  instance.send(
    JSON.stringify({
      type: 'send_client_message',
      data: {
        publisher_hashconnection: getHashConnection(),
        message,
        business_type,
        attached_files
      }
    })
  );
};
