/**
 * Componente de confirmação de fechamento de conversa. É exibido quando o cliente clica no (X) do chat
 * para encerrar a conversa.
 */

import React from 'react';
import { Button } from '@material-ui/core';
import { cleanSessionStorage } from '../../services/chatFunctions';
import { closeService } from '../../services/websocket/messages/closeService';

const ConfirmationBeforeEndChat = ({
  clearMessages,
  setHumanChatInputOpenState,
  socket,
  setClientInteractedWithAttendant,
  setEvaluatingChat
}) => {
  return (
    <>
      Deseja realmente sair desta conversa?
      <br />
      <br />
      <Button
        id="button-close"
        onClick={() => {
          clearMessages();
          setHumanChatInputOpenState(false);
          if (socket instanceof WebSocket) {
            closeService(socket);
          } else {
            socket.sendDisconnect();
          }
          cleanSessionStorage();
          setClientInteractedWithAttendant(false);
          setEvaluatingChat(true);
        }}
        variant="contained"
      >
        Sim. Quero encerrar o chat.
      </Button>
    </>
  );
};

export default ConfirmationBeforeEndChat;
