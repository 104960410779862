import React from 'react';
import { render } from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import App from './App';
import './index.css';
import config from './utils/config';

render(
  <GoogleReCaptchaProvider reCaptchaKey={config.recaptchaSiteKey}>
    <App />
  </GoogleReCaptchaProvider>,
  document.getElementById('root')
);
