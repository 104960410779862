import { Alert } from '@material-ui/lab';
import React from 'react';

export default function AlertConnectionError({ reloadPage, type }) {
  return (
    <Alert severity={type} style={{ marginBottom: '5px' }}>
      <div>
        {' '}
        Foi detectada uma queda na conexão da internet ou do chat. Verifique sua
        conexão e{' '}
        <span
          onClick={reloadPage}
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          aria-hidden="true"
        >
          clique aqui
        </span>{' '}
        para recarregar a página e continuar utilizando o chat.
      </div>
    </Alert>
  );
}
