import { localStorageKeysEnum } from '../../../../utils/applicationKeys';

export const onReceiveTriggerConfiguration = messageFromServer => {
  localStorage.setItem(
    localStorageKeysEnum.TRIGGER_DATA,
    JSON.stringify({
      isActive: true,
      messageClient: messageFromServer.message,
      timeFirstTrigger: messageFromServer.time_first_trigger,
      timeSecondTrigger: messageFromServer.time_second_trigger
    })
  );
};
